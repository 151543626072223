import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Belt } from '../models/Belt';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-belt-list',
  templateUrl: './belt-list.component.html',
  styleUrls: ['./belt-list.component.css']
})
export class BeltListComponent implements OnInit {
  beltList: any;
  beltTitle: any = "Update Belt info";
  isBeltEdit: any = false;
  isBeltAdd: any = false;
  isBeltList: any = true;
  belt = new Belt();
  BeltForm: FormGroup;
  submitted = false;
  beltversionlist: any = [];
  masterSelected: boolean;
  checkedList: any=[];
  readBeltPermission:boolean= false;
  editBeltPermission:boolean=false;
  deleteBeltPermission:boolean=false;
  addBeltPermission:boolean=false;
  IsSelectBeltVersion:boolean=true;
  constructor(private _methods:CommonMethods,private formBuilder: FormBuilder, private studentService: StudentService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit() {
    localStorage.removeItem("StudentId");
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Read))
    {
      this.readBeltPermission=true;
    this.getBeltList();
    }
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Create))
    {
      this.addBeltPermission=true;
    }
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Update))
    {
      this.editBeltPermission=true;
    }
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Delete))
    {
      this.deleteBeltPermission=true;
    }
    this.BeltForm = this.formBuilder.group({
      BeltName: ['', Validators.required],
      IsActive: ['', Validators.required],
      BeltVersion: ['']
    });
  }

  //Get Belt List
  getBeltList() {
    this.studentService.GetAllBelt().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
      }

    });
  }

  //Add New Belt
  addBelt() {
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Create))
    {
    this.isBeltAdd = true;
    this.isBeltEdit = false;
    this.isBeltList = false;
    this.beltTitle = "Add New Belt";
    this.belt = new Belt();
    this.belt.BeltName = "";
    this.belt.IsActive = "true";
    this.getBeltVersionList("");
    }
    else
    {
      this.toastr.error("You don't have permission.");
    }
  }
  get f() { return this.BeltForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.BeltForm.invalid) {
      return;
    }
    if (this.isBeltAdd == true) {
      if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Create))
      {
        if(this.checkedList.length>0 && !isNullOrUndefined(this.checkedList))
        {
          this.IsSelectBeltVersion=true;
          let belversionstring=this.checkedList.join(",");
          this.belt.BeltVersion=belversionstring+",";
        }
        else
        {
          this.IsSelectBeltVersion=false;
          return;
        }
      
        this.studentService.InsertBelt(this.belt).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.getBeltList();
          this.isBeltEdit = false;
          this.isBeltAdd = false;
          this.beltTitle = "Update Belt info";
          this.isBeltList = true;
          this.toastr.success(res.Message);
        } else if (res.StatusCode === 409) {
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {

        this.toastr.error(error, 'Adding Belt Failed!');
      });
      }
      else
      {
        this.toastr.error("You don't have permission.");
      }
    }
    if (this.isBeltEdit == true) {
      if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Update))
      {
      if(this.checkedList.length>0)
      {
        let belversionstring=this.checkedList.join(",");
        this.belt.BeltVersion=belversionstring+",";
      }
      this.studentService.UpdateBelt(this.belt).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.getBeltList();
          this.isBeltEdit = false;
          this.isBeltAdd = false;
          this.beltTitle = "Update Belt info";
          this.isBeltList = true;
        } else if (res.StatusCode === 409) {
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {

        this.toastr.error(error, 'Update Belt Failed!');
      });
      }
      else
      {
        this.toastr.error("You don't have permission.");
      }
    }
  }

  CancelAdd() {
    this.isBeltList = true;
    this.isBeltAdd = false;
    this.isBeltEdit = false;
    this.beltTitle = "Update Belt info";
  }

  //Edit Belt
  editBelt(item) {
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Update))
      {
         this.isBeltList = false;
         this.isBeltAdd = false;
         this.isBeltEdit = true;
         this.beltTitle = "Belt Edit";

         this.belt = item;
         this.getBeltVersionList(item);
      }
      else
      {
        this.toastr.error("You don't have permission.");
      }
  }

  //Delete Belt
  deleteBelt(Belt) {
    if(this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Delete))
    {
      if (confirm("Are you sure to inactive this belt")) {
      this.studentService.DeleteBelt(Belt).subscribe((res: any) => {
        // this.ShowSpinner = false;  
        if (res.StatusCode === 200) {
          this.toastr.success(res.Data);
          this.getBeltList();
          //this._router.navigate(['login']);
        } else if (res.statusCode === 409) {
          this.toastr.error(res.message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {
        // toaster config --message, title, ToastConfig       
        this.toastr.error(error, 'Belt Deleting Failed!');
      });
      }
    }
    else
    {
      this.toastr.error("You don't have permission.");
    }
  }

  //get beltversion list
  getBeltVersionList(item: any) {
    this.studentService.GetBeltVerionList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltversionlist = res.Data;
        for (var i = 0; i < this.beltversionlist.length; i++) {
          if (this.isBeltEdit) {
            this.beltversionlist[i].isSelected = false;
            if (!isNullOrUndefined(item.BeltVersion)) {
              let beltversion = item.BeltVersion.split(",");
              this.checkedList=beltversion;
              for (var j = 0; j < beltversion.length; j++) {
                  if(beltversion[j]==this.beltversionlist[i].BeltVersionId)
                  {
                    this.beltversionlist[i].isSelected = true;
                  }
              }
            }
          }
          else {
            this.beltversionlist[i].isSelected = false;
          }

        }

      }

    });
  }
  isAllSelected() {
   this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    this.IsSelectBeltVersion=true;
    for (var i = 0; i < this.beltversionlist.length; i++) {
      if (this.beltversionlist[i].isSelected)
      this.checkedList.push(this.beltversionlist[i].BeltVersionId)
    }
   
  }
}
