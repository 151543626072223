import { Component, ElementRef, OnInit,ViewChild  } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { FeedBack } from '../models/FeedBack';
import { ModulesEnum } from '../models/ModulesEnum';
import { AuthenticationService } from '../services/authentication.service';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  @ViewChild('attechmentFile')
  myAttechmentVariable: ElementRef;
  feedBackForm: FormGroup;
  feedback=new FeedBack();
  submitted = false;
  currentUser:any;
  severityItem:any=[{name:'Low',value:'Low'},
                    {name:'Medium',value:'Medium'},
                    {name:'Urgent (Stopping from working)', value:'Urgent (Stopping from working)'}
                   ]
  Attechment:File;
  moduleEnum=ModulesEnum;
  moduleList:any=[];
  // keys() : Array<string> {
  //   var keys = Object.keys(this.moduleEnum);
  //   return keys.slice(keys.length / 2);
  // }
  constructor(private _methods:CommonMethods, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private toastr: ToastrService, private _router: Router) { 
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);
    this.feedback.Severity="Low";
  }

  ngOnInit() {
    this.feedBackForm = this.formBuilder.group({
      Subject: ['', Validators.required],
      Description: ['', Validators.required],
      Severity: ['Low', Validators.required],
      ErrorPage:[]
      });
      this.getModules();
  }
  get f() { return this.feedBackForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.feedBackForm.invalid) {
      return;
    }
  this._methods.showLoader();
   this.feedback.UserName=this.currentUser.FirstName + " " + this.currentUser.LastName;
   this.feedback.Role=this.currentUser.Role;
   if(isNullOrUndefined(this.Attechment))
   {
    this.feedback.Attechment=null;
   }
   this.feedback.Attechment=this.Attechment;
   this.feedback.Center=this.currentUser.LoggedInCenter;
   if(isNullOrUndefined(this.feedback.ErrorPage)){
     this.feedback.ErrorPage="";
   }
    this.authenticationService.userFeedBack(this.feedback).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.toastr.success("Feedback sent successfully.");
        this.feedBackForm.reset();
        this.feedback.Severity="Low";
        this.feedBackForm = this.formBuilder.group({
          Subject: ['', Validators.required],
          Description: ['', Validators.required],
          Severity: ['Low', Validators.required],
          ErrorPage:[]
          });
          this.Attechment=null;
          this.myAttechmentVariable.nativeElement.value = "";
      } else if (res.StatusCode === 409) {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.statusCode === 400) {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {
      this._methods.hideLoader();
      this.toastr.error(error, 'Sending feedback failed!');
    });
  }

  getFileDetails (e) {
    if(e.target.files.length>0)
    {
      this.Attechment=e.target.files[0];
      return;
    }
    else
    {
      this.Attechment=null;
    }
    
     
  }

  getModules(){
    this.authenticationService.GetModules().subscribe((res:any)=>{
      if (res.StatusCode === 200) {
        this.moduleList = res.Data;
       
      }
      else {
        this.moduleList = [];
      }
    })
  }
}
