import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  UserForm: FormGroup;
  user = new User();
  tempuserDetail = new User();
  submitted = false;
  currentUser;
  rolelist: any;
  centerList: any;
  centerSelectedList: any;
  isvalidUsercenter: any = true;
  constructor(private _methods: CommonMethods, private authenticationService: AuthenticationService, private _router: Router, private formBuilder: FormBuilder, private toastr: ToastrService) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);
    //this.getcenterList("");
    if (!this._methods.CheckUserPermission(ModulesEnum.ManageUser, PermissionEnum.Update)) {
      _methods.logOut();
    }
  }

  ngOnInit() {
    this.UserForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      UserName: ['', Validators.required],
      Password: ['', Validators.required],
      IsActive: ['', Validators.required],
      RoleId: ['', Validators.required]
    });
    let senseiId = atob(window.localStorage.getItem("SenseiId"));
    if (!isNullOrUndefined(senseiId)) {
      this.getUserByID(senseiId);
    }
    this.getRoleList();
  }
  get f() { return this.UserForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.UserForm.invalid) {
      return;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.ManageUser, PermissionEnum.Update)) {
      if (this.centerSelectedList.length > 0 && !isNullOrUndefined(this.centerSelectedList)) {
        this.isvalidUsercenter = true;
        let centerid = this.centerSelectedList.join(",");
        this.user.UserCenter = centerid + ",";
      }
      else {
        this.isvalidUsercenter = false;
        return;
      }
      if (!isNullOrUndefined(localStorage.getItem("tempusername"))) {
        if (atob(localStorage.getItem("tempusername")) != this.user.UserName) {
          this.authenticationService.UserExists(this.user).subscribe((res: any) => {
            if (res.StatusCode === 200) {
              if (res.Data == true) {
                this.toastr.error("UserName already exists.");
                return;
              }
              else {
                this.updateUser();
              }

            }
          });
        }
        else {
          this.updateUser();
        }
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }

  }

  updateUser() {
    this.user.ModifiedBy = this.currentUser.UserID;
    this.user.IsActive = this.user.IsActive == "1" ? true : false;
    this.authenticationService.UpdateUser(this.user).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this._router.navigate(['userlist'])
      }
      else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Updating User Failed!');
    });
  }

  //get user detail
  getUserByID(userId) {
    this.authenticationService.GetUserById(userId).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.user = res.Data;
        localStorage.setItem("tempfirstname", btoa(res.Data.FirstName));
        localStorage.setItem("templastname", btoa(res.Data.LastName));
        localStorage.setItem("tempusername", btoa(res.Data.UserName));
        this.user.IsActive = res.Data.IsActive === true ? 1 : 0;
        this.tempuserDetail = this.user;
        this.centerSelectedList = this.user.UserCenter;
        this.getcenterList(this.user);

      } else if (res.statusCode === 409) {
        this.toastr.error(res.message, res.Data);
        this._router.navigate(['userlist']);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
        this._router.navigate(['userlist']);
      }
      else {
        this.toastr.error(res.Message, res.Data);
        this._router.navigate(['userlist']);
      }
    }, error => {
      // toaster config --message, title, ToastConfig       
      this.toastr.error(error, 'user not found');
      //console.log(error);
    });

  }

  //cancel to redirect on list
  cancelSaveUser() {
    this._router.navigate(['userlist'])
  }

  getRoleList() {
    this.authenticationService.getRoleList().subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.rolelist = res.Data;
      }
      else {
        this.rolelist = [];
      }
    });
  }
  getcenterList(item: any) {
    this.centerSelectedList = [];
    this.authenticationService.GelAllCenter().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.centerList = res.Data;
        for (var i = 0; i < this.centerList.length; i++) {

          this.centerList[i].isSelected = false;
          if (!isNullOrUndefined(item.UserCenter)) {
            let usercenterid = item.UserCenter.split(",");
            this.centerSelectedList = usercenterid;
            for (var j = 0; j < usercenterid.length; j++) {
              if (usercenterid[j] == this.centerList[i].CenterId) {
                this.centerList[i].isSelected = true;
              }
            }
          }


        }
      }
      else {
        this.centerList = [];
      }
    });
  }

  centerSelectionChange() {
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.centerSelectedList = [];
    this.isvalidUsercenter = true;
    for (var i = 0; i < this.centerList.length; i++) {
      if (this.centerList[i].isSelected)
        this.centerSelectedList.push(this.centerList[i].CenterId)
    }

  }
}
