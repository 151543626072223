export class Game{
    GameId:any;
    GameName:any;
    BeltId:any;
    BeltVersionId:any;
    CreatedBy:any;
    CreatedDate:any;
    ModifiedBy:any;
    ModifiedDate:any;
    IsActive:any;
    SortOrder:any;
}