import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ExcelService } from '../services/excel.service';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.css']
})
export class GameListComponent implements OnInit {
  gameList: any;
  readGameListPermission:boolean=false;
  addGamePermission:boolean=false;
  editGamePermission:boolean=false;
  constructor(private _methods:CommonMethods, private studentService: StudentService, private toastr: ToastrService, private _router: Router, private excelService: ExcelService) { }

  ngOnInit() {
  if(this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Read))
  {
    this.readGameListPermission=true;
    this.getGameList();
  }
  if(this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Create))
  {
    this.addGamePermission=true;
  }
  if(this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Update))
  {
    this.editGamePermission=true;
  }
  }

  //Get Game List
  getGameList() {
    this._methods.showLoader();
    this.studentService.GetAllGame().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.gameList = res.Data;
        setTimeout(function () {
          $(function () {
           
            var table = $('#gamelist-table').DataTable({
              'order': [[5, 'asc']],
              'destroy': true
            });
            // Apply the search
            table.columns().every(function () {
              var that = this;
              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this['value']) {
                  that
                    .search(this['value'])
                    .draw();
                }
              });
            });

            
          });
        });
      }else if(res.StatusCode="404")
      {
        this._methods.hideLoader();
        this.gameList=[];
      }

    });
  }

  addGame(): void {
   if(this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Create))
  {
    localStorage.removeItem("GameId");
    this._router.navigate(['addgame']);
  }
  };

  editGame(gameId) {
  if(this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Update))
  {
    localStorage.removeItem("GameId");
    localStorage.setItem("GameId", btoa(gameId));
    this._router.navigate(['addgame']);
  }
  }

  downloadGame() {
    let tempgamelist = this.gameList;
    for (let i = 0; i < tempgamelist.length; i++) {
      delete tempgamelist[i]["BeltId"];
      delete tempgamelist[i]["BeltVersionId"];
    }
    this.excelService.exportAsExcelFile(tempgamelist, 'GameReport');
  }

}
