export class AppConstant {

   // public static Base_Url = 'https://localhost:44336/api';

   // public static Base_Url = 'https://localhost:5001/api';

   //ublic static Base_Url = 'https://cliftonpark.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'http://staging.codeninjaspeoria.com/CodeNinjaApi/api';
   // public static Base_Url = 'https://senseiportal.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://snellville.codeninja.app/CodeNinjaApi/api';

   //18 Jan2021
   //public static Base_Url = 'https://peoria.codeninja.app/CodeNinjaApi/api';

   //22 Jan2021
   public static Base_Url = 'https://arcolaeast.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://windermere.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://mardaloop.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://stcatharines.codeninja.app/CodeNinjaApi/api';


   // public static Base_Url = 'https://burkeva.codeninja.app/CodeNinjaApi/api';
   //public static Base_Url = 'https://weddington.codeninja.app/CodeNinjaApi/api';

   // 18 July 2021

   //public static Base_Url = 'https://lawrence.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://leawood.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://lenexa.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://leessummit.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://olathe.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://springfield.codeninja.app/CodeNinjaApi/api';
   // public static Base_Url = 'https://stjoseph.codeninja.app/CodeNinjaApi/api';



}