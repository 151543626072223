export enum ModulesEnum {
    LogNinjaActivity = 1,
    ManageNinjaInfo = 2,
    Beltup = 3,
    SenseiDailyLog = 4,
    NinjaDailyLog = 5,
    NinjaScanReport = 6,
    GraduationReport = 7,
    ManageGameInfo = 8,
    UpdateBeltInfo = 9,
    ManageUser = 10,
    UploadCustomerInfo = 11,
    UploadNinjaScanInfo = 12,
    RegisterUser = 13,
    ManageGameDetails = 14,
    ManageProgramReports = 15
}