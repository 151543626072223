import { Component, OnInit } from '@angular/core';
import { DailyUpdate } from '../models/DailyUpdate';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { StudentService } from '../services/student.service';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-daily-update',
  templateUrl: './daily-update.component.html',
  styleUrls: ['./daily-update.component.css']
})
export class DailyUpdateComponent implements OnInit {
  dailyUpdate = new DailyUpdate();
  submitted = false;
  updateForm: FormGroup;
  currentUser;
  tital = "Sensei Daily Log";
  isClosingSensei: boolean = false;
  iseditmode: boolean = false;
  constructor(private _methods:CommonMethods, private formBuilder: FormBuilder, public route: ActivatedRoute, private studentService: StudentService, private authenticationService: AuthenticationService, private toastr: ToastrService, private _router: Router) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);
  }

  ngOnInit() {
    let dailyupdateId = this.route.snapshot.params.id;
    if (!isNullOrUndefined(dailyupdateId)) {
      if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Update)) {
      this.GetDailyUpdate(atob(dailyupdateId))
      this.iseditmode = true;
      this.tital = "Edit Sensei Daily Log";
      }
      else
      {
        this.toastr.error("You don't have permission.");
      }
    }
    else {
      if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Create)) {
      this.iseditmode = false;
      this.tital = "Sensei Daily Log";
      }
      else
      {
        this.toastr.error("You don't have permission.");
      }
    }
    this.updateForm = this.formBuilder.group({
      Comment: ['', Validators.required],
      RedCoin: [''],
      BlueCoin: [''],
      GreenCoin: [''],
      YellowCoin: ['']

    });
  }
  get f() { return this.updateForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.updateForm.invalid) {
      return;
    }
    if (this.iseditmode) {
      if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Update)) {
      this.authenticationService.UpdateDailyUpdate(this.dailyUpdate).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.toastr.success(res.Data);
          this._router.navigate(['dailyreport']);
        }
        else if (res.StatusCode === 409) {
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {

        this.toastr.error(error, 'Updating Daily Update Failed!');
      });
      }
      else
      {
        this.toastr.error("You don't have permission.");
      }
    }
    else {
      if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Create)) {
      this.dailyUpdate.CreatedBy = this.currentUser.UserID;
      this.studentService.InsertDailyUpdate(this.dailyUpdate).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.toastr.success(res.Data);
        }
        else if (res.StatusCode === 409) {
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {

        this.toastr.error(error, 'Adding Daily Update Failed!');
      });
    }
    else
      {
        this.toastr.error("You don't have permission.");
      }
    }
  }

  //Allow Only digit
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  ChangeIsClosing(value) {
    if (!value) {
      this.updateForm = this.formBuilder.group({
        Comment: [this.dailyUpdate.Comment, Validators.required],
        RedCoin: [this.dailyUpdate.RedCoin],
        BlueCoin: [this.dailyUpdate.BlueCoin],
        GreenCoin: [this.dailyUpdate.GreenCoin],
        YellowCoin: [this.dailyUpdate.YellowCoin]

      });
    }
    else {
      this.updateForm = this.formBuilder.group({
        Comment: [this.dailyUpdate.Comment, Validators.required],
        RedCoin: [this.dailyUpdate.RedCoin, Validators.required],
        BlueCoin: [this.dailyUpdate.BlueCoin, Validators.required],
        GreenCoin: [this.dailyUpdate.GreenCoin, Validators.required],
        YellowCoin: [this.dailyUpdate.YellowCoin, Validators.required]

      });
    }
  }

  validator(control) {
    const form_field = this.updateForm.get(control);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  GetDailyUpdate(id) {
    this.authenticationService.GetDailyUpdateById(id).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.dailyUpdate = res.Data;

      } else if (res.statusCode === 409) {
        this.toastr.error(res.message, res.Data);
        this._router.navigate(['dailyreport']);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
        this._router.navigate(['dailyreport']);
      }
      else {
        this.toastr.error(res.Message, res.Data);
        this._router.navigate(['dailyreport']);
      }
    }, error => {
      // toaster config --message, title, ToastConfig       
      this.toastr.error(error, 'DailyUpdate not found');
    });
  }

  CancelAdd() {
    this.iseditmode = false;
    this.tital = "Sensei Daily Log";
    this._router.navigate(['dailyreport'])
  }

}
