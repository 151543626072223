import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { StudentHistoryComponent } from './student-history/student-history.component';
import { AddstudentComponent } from './addstudent/addstudent.component';
import { ImportStudentComponent } from './import-student/import-student.component';
import { StudentListComponent } from './student-list/student-list.component';
import { StudentGraphComponent } from './student-graph/student-graph.component';
import { StudentActivityReportComponent } from './student-activity-report/student-activity-report.component';
import { BeltupComponent } from './beltup/beltup.component';
import { DailyUpdateComponent } from './daily-update/daily-update.component';
import { BeltListComponent } from './belt-list/belt-list.component';
import { BeltGraduationComponent } from './belt-graduation/belt-graduation.component';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { NinjaActivityHistoryComponent } from './ninja-activity-history/ninja-activity-history.component';
import { NinjaUploadHistoryComponent } from './ninja-upload-history/ninja-upload-history.component';
import { AuthGuard } from './services/auth.guard';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { GameListComponent } from './game-list/game-list.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { ProgramReportsComponent } from './program-reports/program-reports.component';
import { GameAddComponent } from './game-add/game-add.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NinjaInfoComponent } from './ninja-info/ninja-info.component';
import { NinjaDailyLogComponent } from './ninja-daily-log/ninja-daily-log.component';
import { CenterLoginComponent } from './center-login/center-login.component';
import { FeedbackComponent } from './feedback/feedback.component';
const routes: Routes = [
  //{ path: '', component: HomeComponent }, 
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegistrationComponent, canActivate: [AuthGuard] },
  { path: 'studenthistory', component: StudentHistoryComponent, canActivate: [AuthGuard] },
  { path: 'importstudent', component: ImportStudentComponent, canActivate: [AuthGuard] },
  { path: 'addstudent', component: AddstudentComponent, canActivate: [AuthGuard] },
  { path: 'studentlist', component: StudentListComponent, canActivate: [AuthGuard] },
  { path: 'studentgraph', component: StudentGraphComponent, canActivate: [AuthGuard] },
  { path: 'StudentActivityReport', component: StudentActivityReportComponent, canActivate: [AuthGuard] },
  { path: 'beltup', component: BeltupComponent, canActivate: [AuthGuard] },
  { path: 'dailyupdate', component: DailyUpdateComponent, canActivate: [AuthGuard] },
  { path: 'dailyupdate/:id', component: DailyUpdateComponent, canActivate: [AuthGuard] },
  { path: 'beltlist', component: BeltListComponent, canActivate: [AuthGuard] },
  { path: 'beltgraduation', component: BeltGraduationComponent, canActivate: [AuthGuard] },
  { path: 'dailyreport', component: DailyReportComponent, canActivate: [AuthGuard] },
  { path: 'customeruploadhistory', component: NinjaUploadHistoryComponent, canActivate: [AuthGuard] },
  { path: 'ninjaactivityhistory', component: NinjaActivityHistoryComponent, canActivate: [AuthGuard] },
  { path: 'userlist', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'edituser', component: UserEditComponent, canActivate: [AuthGuard] },
  { path: 'gamelist', component: GameListComponent, canActivate: [AuthGuard] },
  { path: 'gamedetails', component: GameDetailsComponent, canActivate: [AuthGuard] },
  { path: 'programreports', component: ProgramReportsComponent, canActivate: [AuthGuard] },
  { path: 'addgame', component: GameAddComponent, canActivate: [AuthGuard] },
  { path: 'sidebar', component: SidebarComponent, canActivate: [AuthGuard] },
  { path: 'ninjainfo/:id', component: NinjaInfoComponent, canActivate: [AuthGuard] },
  { path: 'ninjadailylog', component: NinjaDailyLogComponent, canActivate: [AuthGuard] },
  { path: 'centerlogin/:id', component: CenterLoginComponent },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard] }

]
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
