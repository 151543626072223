import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstant } from '../helper/app.constants';
import { debug } from 'util';
import { Student } from '../models/student';
import { DailyUpdate } from '../models/DailyUpdate';
import { StudentHistory } from '../models/studenthistory';
import { ProgramReportSearch } from '../models/ProgramReport';
import { Belt } from '../models/Belt';
import { Game } from "../models/Game";
import { StudentBeltUp } from '../models/studentBeltUp';
import { DateRangeFilterWithstudentId } from '../models/DateRangeFilterWithstudentId';
import { GameDetail } from '../models/GameDetail';
@Injectable({
  providedIn: 'root'
})
export class StudentService {
  url: string = AppConstant.Base_Url + '/Student';
  constructor(private http: HttpClient) { }

  // import ninja
  StudentImport(File) {
    const frmData = new FormData();
    frmData.append("fileUpload", File);
    return this.http.post(this.url + '/UploadFiles', frmData);
  }

  // Insert Student
  InsertStudent(studentData: Student) {
    return this.http.post(this.url + '/InsertStudent', studentData);
  }

  //Update Student
  UpdateStudent(studentData: Student) {
    // console.log(userData);  
    return this.http.post(this.url + '/UpdateStudent', studentData);
  }

  //Get Student List
  StudentList() {
    return this.http.post(this.url + '/StudentList', "");
  }

  //Get EventType List
  EventTypeList() {
    return this.http.post(this.url + '/EventTypeList', "");
  }


  GetProgramReports(model: ProgramReportSearch) {
    return this.http.post(this.url + '/ProgramReportSearch' , model);
  }

  //Student list by name filter
  StudentListByName(name, status) {
    return this.http.get(this.url + '/StudentListByName?name=' + name + '&status=' + status);
  }

  GetStudentById(studentId: any) {
    return this.http.get(this.url + '/StudentById?studentId=' + studentId);
  }

  DeleteStudent(studentId: any) {
    return this.http.get(this.url + '/DeleteStudent?studentId=' + studentId);
  }

  //Get student history by student id
  StudentHistoryById(studentId: any) {

    return this.http.get(this.url + '/StudentHistoryById?studentId=' + studentId);
  }

  //Save Student History
  SaveStudentHistory(studentHistoryData: StudentHistory) {

    return this.http.post(this.url + '/InsertStudentHistory', studentHistoryData);
  }

  //Save Student History
  LogStudentHistory(studentHistoryData: StudentHistory) {

    return this.http.post(this.url + '/LogStudentHistory', studentHistoryData);
  }

  //Get Active Belt List
  GetBeltList() {
    return this.http.post(this.url + '/BeltList', "");
  }

  //Get Game List
  GetGameList() {
    return this.http.post(this.url + '/GameList', "");
  }

  //Get Game List By BeltId
  GetGameListByBeltId(beltid) {
    return this.http.get(this.url + '/GameListByBeltId?beltId=' + beltid);
  }

  StudentGraphDataById(studentId: any) {
    return this.http.get(this.url + '/StudentGraphDataById?studentId=' + studentId);
  }

  //Import Student Activity
  StudentActivityImport(File) {
    const frmData = new FormData();
    frmData.append("fileUpload", File);
    return this.http.post(this.url + '/UploadStudentActivityReport', frmData);
  }

  //Save Belt
  InsertBelt(belt: Belt) {
    return this.http.post(this.url + '/AddBelt', belt);
  }

  //Update Belt Information
  UpdateBelt(belt: Belt) {
    return this.http.post(this.url + '/UpdateBelt', belt);
  }

  //Delete Belt
  DeleteBelt(belt: Belt) {

    return this.http.post(this.url + '/DeleteBelt', belt);
  }

  //Update Student Belt up
  UpdateStudentBeltUp(student: StudentBeltUp) {

    return this.http.post(this.url + '/UpdateStudentBeltUp', student);
  }

  //Get All Belt
  GetAllBelt() {
    return this.http.post(this.url + '/GetAllBelt', "");
  }

  //Save Daily Update
  InsertDailyUpdate(dailyUpdate: DailyUpdate) {
    return this.http.post(this.url + '/InsertDailyUpdate', dailyUpdate);
  }

  //Save Student Comment
  SaveComment(studentHistory: StudentHistory) {

    return this.http.post(this.url + '/SaveStudentComment', studentHistory);
  }

  //Get beltversion list
  GetBeltVerionList() {
    return this.http.post(this.url + '/GetAllBeltVersion', "");
  }

  //Get History of Import Ninja
  GetImportFileHistory(importtype) {

    return this.http.get(this.url + '/GetImportFileHistory?importType=' + importtype);
  }

  //Get Belt Version List By BeltId
  GetBeltVersionByBeltId(beltid) {
    return this.http.get(this.url + '/GetBeltVersionByBeltId?beltId=' + beltid);
  }

  //Get student activity graph
  GetStudentActivityGraph(dateRangeFilter) {
    return this.http.post(this.url + '/GetStudentActivityGraph', dateRangeFilter);
  }

  //Get Student activity report data by select date
  GetStudentActivityByDate(date: any) {
    return this.http.get(this.url + '/GetStudentActivityByDate?daydate=' + date);
  }

  //Save Student History
  SaveOldCommentInStudentHistory() {

    return this.http.post(this.url + '/InsertOldCommentInStudentHistory', "");
  }

  //Get All Game
  GetAllGame() {
    return this.http.post(this.url + '/GetAllGame', "");
  }

  //Get All Game
  GetAllGameDetails() {
    return this.http.post(this.url + '/GetAllGameDetails', "");
  }

  //Save GameDetail
  InsertGameDetail(gameDetail: GameDetail) {
    return this.http.post(this.url + '/AddGameDetail', gameDetail);
  }

  //Update Belt Information
  UpdateGameDetail(gameDetail: GameDetail) {
    return this.http.post(this.url + '/UpdateGameDetail', gameDetail);
  }

  //Delete Belt
  DeleteGameDetail(gameDetail: GameDetail) {
    return this.http.post(this.url + '/DeleteGameDetail', gameDetail);
  }




  //Get All Game
  GetGameById(gameId) {
    return this.http.get(this.url + '/GetGameById?gameId=' + gameId);
  }

  //Save Game
  InsertGame(game: Game) {
    return this.http.post(this.url + '/AddGame', game);
  }

  //Update Belt Information
  UpdateGame(game: Game) {
    return this.http.post(this.url + '/UpdateGame', game);
  }

  //Check Game Exists
  CheckGameExists(game: Game) {
    return this.http.post(this.url + '/CheckGameExists', game);
  }

  GetNinjaInfo(dateRangeFilterWithStudentId: DateRangeFilterWithstudentId) {
    return this.http.post(this.url + '/GetNinjaInfo', dateRangeFilterWithStudentId);
  }

  //Get Ninja History By Date
  GetStudentHistoryByDate(dateRangeFilter) {
    return this.http.post(this.url + '/GetStudentHistoryByDate', dateRangeFilter);
  }

  //Delete Student History
  DeleteStudentHistory(studentHistoryId: any) {
    return this.http.get(this.url + '/DeleteStudentHistory?studentHistoryId=' + studentHistoryId);
  }
  UpdateStudentHistory(studentHistory: StudentHistory) {
    return this.http.post(this.url + '/UpdateStudentHistory', studentHistory);
  }
  //Sent Missed Session Email
  MissedSessionLog(studentHistoryData: StudentHistory) {

    return this.http.post(this.url + '/MissedSessionLog', studentHistoryData);
  }
}
