import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { StudentService } from '../services/student.service';
import { debug } from 'util';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import * as moment from 'moment';
import { DateRangeFilter } from '../models/DateRangeFilter';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-student-graph',
  templateUrl: './student-graph.component.html',
  styleUrls: ['./student-graph.component.css']
})
export class StudentGraphComponent implements OnInit {
  data: any = [];
  Barchart: Chart;
  newArr: any;
  studentGraphData: any=[];
  @Input() childMessage: string;
  @ViewChild('canvas') chartElementRef: ElementRef;
  studentactivitylist: any = [];
  date = new Date();
  startdate = new Date();
  
  dateRangeFilter = new DateRangeFilter();
  fromoptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    //minDate: new Date(Date.now()), // Minimal selectable date
    maxDate: new Date(this.date),  // Maximal selectable date
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  tooptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    minDate:this.startdate , // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'searchtoDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  readStudentGraphData:Boolean=false;
  constructor(private _methods:CommonMethods, private studentService: StudentService,private toastr: ToastrService ) { }

  ngOnInit() {
    this.startdate.setDate(this.startdate.getDate() - 7);
    this.dateRangeFilter.StartDate = moment(this.startdate).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate = moment(this.date).format('MM-DD-YYYY');
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaScanReport, PermissionEnum.Read)) {
      this.readStudentGraphData=true;
    this.GetGraphData();
    }
  }

  GetStudentHistoryById(studentId) {
    this.studentService.StudentGraphDataById(studentId).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.studentGraphData = res.Data;
        let gamelist = [];
        let spentHoursList = [];
        this.studentGraphData.forEach(element => {
          gamelist.push(element.GameName);
          spentHoursList.push(element.SpentHours);
        });
        this.Barchart = new Chart('canvas', {
          type: 'line',
          data: {
            labels: gamelist,

            datasets: [
              {
                data: spentHoursList,
                borderColor: '#3cb371',
                //backgroundColor: "#FFF",
              }
            ]
          },
          options: {
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                display: true
              }],
              yAxes: [{
                display: true
              }],
            }
          }
        });
      } else if (res.StatusCode === 404) {
        this.studentGraphData = [];
      }
      else if (res.StatusCode === 400) {
        this.studentGraphData = [];
      }
      else {
        this.studentGraphData = [];
      }
    }, error => {
      //      this.toastr.error(error, 'student History Failed!');

    });
  }

  GetGraphData() {
    if (this.Barchart) {
      this.Barchart.destroy();
    }
    this.studentactivitylist=[];
    this.dateRangeFilter.StartDate=moment(this.dateRangeFilter.StartDate).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate=moment(this.dateRangeFilter.EndDate).format('MM-DD-YYYY');
    this.studentService.GetStudentActivityGraph(this.dateRangeFilter).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.studentGraphData = res.Data;
        
        let sessionHoursList = [];
        let sessionDateList = [];
        let backgroundColor = [];
        this.studentGraphData.forEach(element => {
          sessionHoursList.push(element.SessionCount);
          sessionDateList.push(moment(element.SessionDate).format('MM/DD/YYYY ddd'));
        });
        
        for (var i = 0; i < this.studentGraphData.length; i++) {
            // We generate a random color
            var color = "rgba(" + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + ",";

            // We push this new color to both background and border color arrays
            // .. a lighter color is used for the background
            backgroundColor.push(color + "0.2)");
          
        }
        let tempdata = {
          labels: sessionDateList,
          datasets: [{
            data: sessionHoursList,
            backgroundColor: backgroundColor,
          
            borderWidth: 1
          }]
        }
        // let context = this.chartElementRef.nativeElement;
        let context = document.getElementById('canvas');
        this.Barchart = new Chart(context, {
          type: 'bar',
          data: tempdata,
          options: {
            responsive: true,
            "animation": {
              "duration": 1,
              "onComplete": function () {
                var chartInstance = this.chart,
                  ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                  });
                });
              }
            },
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                ticks: {
                  maxRotation: 90,
                  minRotation: 80
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            },
            // events: ['click'],
            //  onClick: this.onClick
            onClick: (e, array) => {
              this.onClicks(e, array)
            }

          }
        });
      } else if (res.StatusCode === 404) {
        if (this.Barchart) {
          this.Barchart.destroy();
        }
        this.studentGraphData = [];
        this.Barchart = new Chart('canvas', {
          type: "bar",
          data: {},
          options: {}
        });

      }
      else if (res.StatusCode === 400) {
        this.studentGraphData = [];
      }
      else {
        this.studentGraphData = [];
      }
    }, error => {
      //      this.toastr.error(error, 'student History Failed!');

    });
  }

  onClicks(event, array) {
    if (array.length > 0) {
      let chartitem = array[0];
      let labeldate = chartitem._model.label;
      var momentDateObj = moment(labeldate, "MM/DD/YYYY").format('MM-DD-YYYY');
      this.getStudentActivityByDate(momentDateObj);

    }
  }

  //get student activity by selected date
  getStudentActivityByDate(date) {
    this.studentService.GetStudentActivityByDate(date).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.studentactivitylist = res.Data;
      } else if (res.StatusCode === 404) {
        this.studentactivitylist = [];
      }
      else if (res.StatusCode === 400) {
        this.studentactivitylist = [];
      }
      else {
        this.studentactivitylist = [];
      }
    }, error => {
      //      this.toastr.error(error, 'student History Failed!');

    });
  }

  getStudentGraph() {
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaScanReport, PermissionEnum.Read)) {
    this.studentactivitylist=[];
    this.GetGraphData();
    }
    else
    {
      this.toastr.error("You don't have permission.");
    }
  }

  //from date change event
  fromchange(event) {
    this.tooptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      minDate: moment(this.dateRangeFilter.StartDate).toDate(),
      // maxDate:this.dateRangeFilter.EndDate,
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'searchtoDate',
      useEmptyBarTitle: false,
    }
   
  }

  tochange(event){
    this.fromoptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      // minDate: this.dateRangeFilter.StartDate,
      maxDate:moment(this.dateRangeFilter.EndDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {},
      fieldId: 'searchFromDate',
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    };
  }
}
