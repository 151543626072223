import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Student } from '../models/student';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { StudentBeltUp } from '../models/studentBeltUp';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-beltup',
  templateUrl: './beltup.component.html',
  styleUrls: ['./beltup.component.css']
})
export class BeltupComponent implements OnInit {
  studentData: any = [];
  tempStudentData: any;
  beltlist: any = [];
  beltversionlist: any = [];
  isStudentList: boolean = true;
  student = new StudentBeltUp();
  BeltupForm: FormGroup;
  isEditBelt: boolean = false;
  submitted = false;
  currentUser;
  beltupeditpermission:boolean=false;
  bletupReadPermission:boolean=false;
  constructor(private _methods:CommonMethods, private studentService: StudentService, private toastr: ToastrService, private _router: Router, private formBuilder: FormBuilder, ) { }

  ngOnInit() {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);

    localStorage.removeItem("StudentId");
    if(this._methods.CheckUserPermission(ModulesEnum.Beltup, PermissionEnum.Read))
    {
      this.bletupReadPermission=true;
    this.getStudent();
    }
    this.BeltupForm = this.formBuilder.group({
      BeltId: ['', Validators.required],
      BeltVersionId: ['', Validators.required],
      FavoriteGame:[''],
      ToughestGame:[''],
      Comment:[''],
      GraduationAttended:[false]
    });
    if(this._methods.CheckUserPermission(ModulesEnum.Beltup, PermissionEnum.Update))
    {
      this.beltupeditpermission=true;
    }
  }
  getStudent() {
    this._methods.showLoader();
    this.studentService.StudentList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.studentData = res.Data;
        setTimeout(function () {
          $(function () {
            $('#beltuplist-table').DataTable({
              'order': [[0, 'asc']]
            });
          });
        });

      }
      else if (res.StatusCode === 404) {
        this._methods.hideLoader();
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }
      else {
        this._methods.hideLoader();
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }

    },
      error => {
        this._methods.hideLoader();
      });
  }

  get f() { return this.BeltupForm.controls; }

  //Edit Belt up
  editBeltUp(item) {
    if(!this._methods.CheckUserPermission(ModulesEnum.Beltup, PermissionEnum.Update))
    {
      this.toastr.error("You don't have permission.");
      setTimeout(() => {
        this._methods.logOut();  
      }, 2000);
      return;
    }
    this.BeltupForm = this.formBuilder.group({
      BeltId: ['', Validators.required],
      BeltVersionId: ['', Validators.required],
      FavoriteGame:[''],
      ToughestGame:[''],
      Comment:[''],
      GraduationAttended:[false]
    });
    this.getBeltList();
    //this.getBeltVersionList(item);
    if (item.BeltId == "0") {
      item.BeltId = "";
    }
    this.student = item;
    if (!isNullOrUndefined(item.BeltId) && item.BeltId != "") {
      this.getBeltVersionListByBeltId(item.BeltId);
    }
    this.isEditBelt = true;
    this.isStudentList = false;
  }

  //get belt list
  getBeltList() {
    this.studentService.GetBeltList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltlist = res.Data;
      }

    });
  }

  //get beltversion list
  getBeltVersionList(item) {
    this.studentService.GetBeltVerionList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltversionlist = res.Data;
        if (isNullOrUndefined(item.BeltVersionId)) {
          let beltversiondata = this.beltversionlist.filter(a => a.BeltVersionNo.trim() == "V2");
          item.BeltVersionId = beltversiondata[0].BeltVersionId;
        }
      }

    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.BeltupForm.invalid) {
      return;
    }
    this.student.ModifiedBy = this.currentUser.UserID;
    if(this._methods.CheckUserPermission(ModulesEnum.Beltup, PermissionEnum.Update))
    {
    this.studentService.UpdateStudentBeltUp(this.student).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this.isEditBelt = false;
        this.isStudentList = true;
        this.getStudent();
      }
      else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Updating Student Belt Failed!');
    });
    }
    else
    {
      this.toastr.error("You don't have permission.");
    }
  }

  cancelBeltUp() {
    this.isEditBelt = false;
    this.isStudentList = true;
    this.getStudent();
  }

  changeBelt(beltId) {
    if (!isNullOrUndefined(beltId) && beltId != "") {
      this.getBeltVersionListByBeltId(beltId);
    }

  }

  //get beltversion list
  getBeltVersionListByBeltId(beltid) {
    this.beltversionlist = [];
    this.studentService.GetBeltVersionByBeltId(beltid).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltversionlist = res.Data;

      }

    });
  }
}
