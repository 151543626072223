export class StudentHistory {  
    StudentId:any;
    BeltId:any;
    GameId:any;
    BeltName:any;
    GameName:any;
    Comment:any;
    Spenthours:any;
    Grade:any;
    SenseiName:any;
    CreatedBy:any;
    CreatedDate:any;
    ModifiedBy:any;
    ModifiedDate:any;
    TeacherName:any;
}