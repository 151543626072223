export class StudentBeltUp {  
    StudentId:any;
    FirstName:any;
    DateOfBirth:any;
    LastName:any;
    AKAName:any;
    Belt:any;
    BeltId:any;
    GameId:any;
    Game:any;
    BeltVersionId:any;
    BeltVersionNo:any;
    Status:any;
    CreatedBy:any;
    CreatedDate:any;
    ModifiedBy:any;
    ModifiedDate:any;
    FavoriteGame:any;
    ToughestGame:any;
    Comment:any;
    GraduationAttended:any;

}