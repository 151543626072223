import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-import-student',
  templateUrl: './import-student.component.html',
  styleUrls: ['./import-student.component.css']
})
export class ImportStudentComponent implements OnInit {
  myFiles:File;
  NoFile:Boolean=false;
  importhistory:any=[];
  readcustomerinfoPermission:boolean=false;
  createcustomerinfoPermission:boolean=false;
  constructor(private _methods:CommonMethods, private studentService:StudentService,private toastr:ToastrService) { }

  ngOnInit() {
    if(this._methods.CheckUserPermission(ModulesEnum.UploadCustomerInfo, PermissionEnum.Read))
    {
      this.readcustomerinfoPermission=true;
      this.getNinjaActivityHistory("Ninja Import");
    }

    if(this._methods.CheckUserPermission(ModulesEnum.UploadCustomerInfo, PermissionEnum.Create))
    {
      this.createcustomerinfoPermission=true;
    }
    
  }
  getFileDetails (e) {
    if(e.target.files.length>0)
    {
      this.myFiles=e.target.files[0];
      this.NoFile=false;
      return;
    }
    else
    {
      this.myFiles=null;
    }
    
     
  }

  uploadFiles () {
  //  const frmData = new FormData();
  if(this._methods.CheckUserPermission(ModulesEnum.UploadCustomerInfo, PermissionEnum.Create))
    {
    if(this.myFiles)
    {
    this.studentService.StudentImport(this.myFiles).subscribe((res: any) => {   
          if (res.StatusCode === 200) 
          {
              this.toastr.success(res.Message,"", { closeButton: true });    
              // this._router.navigate(['studenthistory'])
          }
          else if (res.StatusCode === 404) 
          {
            this.toastr.error(res.Message, res.Data, { closeButton: true });
          }
          else 
          {
            this.toastr.error(res.Message, res.Data, { closeButton: true });
          }
          this.getNinjaActivityHistory("Ninja Import");
        },
        error => {
          this.getNinjaActivityHistory("Ninja Import");
        });
    
      }
      else
      {
        this.NoFile=true;
      }
    }
    else{
      this.toastr.error("You don't have permission.");
    }
  }

  getNinjaActivityHistory(importtype) {
    this.studentService.GetImportFileHistory(importtype).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.importhistory = res.Data;
      }

    });
  }

}
