import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-student-activity-report',
  templateUrl: './student-activity-report.component.html',
  styleUrls: ['./student-activity-report.component.css']
})
export class StudentActivityReportComponent implements OnInit {
  myFiles: File;
  NoFile: Boolean = false;
  importhistory:any=[];
  readninjaPermission:boolean=false;
  createninjaPermission:boolean=false;
  constructor(private _methods:CommonMethods, private studentService: StudentService, private toastr: ToastrService) { }

  ngOnInit() {
    if(this._methods.CheckUserPermission(ModulesEnum.UploadNinjaScanInfo, PermissionEnum.Read))
    {
      this.readninjaPermission=true;
    this.getNinjaActivityHistory("Ninja Activity");
    }
    if(this._methods.CheckUserPermission(ModulesEnum.UploadNinjaScanInfo, PermissionEnum.Create))
    {
      this.createninjaPermission=true;
    }
  }
  getFileDetails(e) {
    //console.log (e.target.files);
    if (e.target.files.length > 0) {
      this.myFiles = e.target.files[0];
      this.NoFile = false;
      return;
    }
    else {
      this.myFiles = null;
    }


  }

  uploadFiles() {
  if(this._methods.CheckUserPermission(ModulesEnum.UploadNinjaScanInfo, PermissionEnum.Create))
  {
    //  const frmData = new FormData();
    if (this.myFiles) {
      this.studentService.StudentActivityImport(this.myFiles).subscribe((res: any) => {
        // console.log(res);
        if (res.StatusCode === 200) {
          this.toastr.success(res.Message, "", { closeButton: true });
          // this._router.navigate(['studenthistory'])
        }
        else if (res.StatusCode === 404) {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }
        else {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }
        this.getNinjaActivityHistory("Ninja Activity");
      },
        error => {

        });

    }
    else {
      this.NoFile = true;
    }
  }
  else
  {
    this.toastr.error("You don't have permission.");
  }
  }

  getNinjaActivityHistory(importtype) {
    this.studentService.GetImportFileHistory(importtype).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.importhistory = res.Data;
      }

    });
  }
}
