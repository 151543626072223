import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-ninja-upload-history',
  templateUrl: './ninja-upload-history.component.html',
  styleUrls: ['./ninja-upload-history.component.css']
})
export class NinjaUploadHistoryComponent implements OnInit {
  importhistory:any=[];
  constructor(private studentService: StudentService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getNinjaActivityHistory("Ninja Import");
  }
  getNinjaActivityHistory(importtype) {
    this.studentService.GetImportFileHistory(importtype).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.importhistory = res.Data;
      }

    });
  }
}
