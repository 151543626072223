import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../helper/Method';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user;
  constructor(private _methods:CommonMethods) { }

  ngOnInit() {
    const user = atob(sessionStorage.getItem('authData'));
    this.user = JSON.parse(user);
  }

  logOut()
  {
    this._methods.logOut();
  }

}
