import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router ,ActivatedRoute} from '@angular/router';
import { CommonMethods } from '../helper/Method';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-center-login',
  templateUrl: './center-login.component.html',
  styleUrls: ['./center-login.component.css']
})
export class CenterLoginComponent implements OnInit {
  userCenter:any=[];
  selectedCenter:any;
  constructor(private _methods:CommonMethods, private authenticationService: AuthenticationService,public route: ActivatedRoute, private toastr: ToastrService, private _router: Router, private _method: CommonMethods) { }

  ngOnInit() {
    let userId = this.route.snapshot.params.id;
    if (!isNullOrUndefined(userId)) {
      this.authenticationService.GetUserCenter(atob(userId)).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.userCenter=res.Data;
          if(!isNullOrUndefined(this.userCenter))
          {
            this.selectedCenter=this.userCenter[0].CenterId;
            if(this.userCenter.length>0 && this.userCenter.length==1)
            {
             this.updateUserCenter(this.userCenter[0].CenterId)
            }
           
          }
        }else if (res.StatusCode === 404) {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
          this._router.navigate(['login'])
        }
        else {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
          this._router.navigate(['login'])
        }

      },
      error => {

      })
    }
  }

  getUserCenter(userId){
    this.authenticationService.GetUserCenter(userId).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.userCenter=res.Data;
        }else if (res.StatusCode === 404) {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }
        else {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }

      },
      error => {

      })
  }
  updateUserCenter(centerId){
    this._methods.showLoader();
    this.authenticationService.UpdateUserLogInCenter(centerId).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.userCenter=res.Data;
           sessionStorage.setItem('authData', btoa(JSON.stringify(res.Data)));
            this._method.setLoginData();
            this._methods.hideLoader();       
            this.toastr.success("Login Successfull.", "", { closeButton: true });
            if(res.Data.RoleId=="2" || res.Data.RoleId=="1"){
              this._router.navigate(['/studenthistory']).then(() => {
                window.location.reload();
              });
            }else if(res.Data.RoleId=="3")
            {
              this._router.navigate(['/dailyreport']).then(() => {
                window.location.reload();
              });
            }else if(res.Data.RoleId=="4")
            {
              this._router.navigate(['/userlist']).then(() => {
                window.location.reload();
              });
            }
        }else if (res.StatusCode === 404) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data, { closeButton: true });
          this._router.navigate(['/login'])
        }
        else if (res.StatusCode === 401) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data, { closeButton: true });
          this._router.navigate(['/login'])
        }
        else {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data, { closeButton: true });
          this._router.navigate(['/login'])
        }

      },
      error => {
        this._methods.hideLoader();
      })
  }
  LoginToCenter(){
    this.updateUserCenter(this.selectedCenter);
  }
}
