import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { GameDetail } from '../models/GameDetail';
import { isNullOrUndefined } from 'util';
import { ExcelService } from '../services/excel.service';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.css']
})
export class GameDetailsComponent implements OnInit {
  gameDetailList: any;
  gameDetail = new GameDetail();
  pageTitle: string = "Manage Game Details";
  readGameDetailPermission: boolean = false;
  addGameDetailPermission: boolean = true;
  editGameDetailPermission: boolean = false;
  isGameDetailAdd: boolean = false;
  isGameDetailEdit: boolean = false;
  isGameDetailList: boolean = true;

  GameDetailForm: FormGroup;
  submitted: boolean = false;


  beltList: any = [];
  gameList: any = [];

  constructor(private _methods: CommonMethods, private formBuilder: FormBuilder, private studentService: StudentService, private toastr: ToastrService, private _router: Router, private excelService: ExcelService) { }

  ngOnInit() {
    if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Read)) {
      this.readGameDetailPermission = true;
      this.getGameDetailList();
    }
    if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Create)) {
      this.addGameDetailPermission = true;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Update)) {
      this.editGameDetailPermission = true;
    }

    this.GameDetailForm = this.formBuilder.group({
      BeltId: ['', Validators.required],
      GameId: ['', Validators.required],
      Description: ['', Validators.required],
      CommandsLearnt: ['', Validators.required],
      ReviewData: ['', Validators.required],
      KnownGdpIssues: ['', Validators.required],
      IsActive: ['', Validators.required],
      BeltVersion: ['']
    });

  }

  // get games for a belt

  getBeltList() {
    this.beltList = [];
    this.studentService.GetBeltList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
        if (this.gameDetail.BeltId == "" && this.beltList.length > 0) {
          this.gameDetail.BeltId = this.beltList["0"].BeltId;
        }
        else {
          this.getGameListByBelt(this.gameDetail.BeltId);
        }
      }

    });
  }
  /* 
    getGameList() {
      this.gameList = [];
      this.studentService.GetGameList().subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.gameList = res.Data;
        }
  
      });
    }*/

  //belt on change event
  beltChangeEvent() {
    if (this.gameDetail.BeltId > 0) {
      this.getGameListByBelt(this.gameDetail.BeltId);
    }
  }

  //get games for a belt
  getGameListByBelt(beltId) {
    this.gameList = [];
    this.studentService.GetGameListByBeltId(beltId).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }


  //Get Game List
  getGameDetailList() {
    this._methods.showLoader();
    this.studentService.GetAllGameDetails().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.gameDetailList = res.Data;
        setTimeout(function () {
          $(function () {

            var table = $('#gameDetailList-table').removeAttr('width').DataTable({
              'order': [[1, 'asc']],
              'destroy': true,
              scrollX: true,
              columnDefs: [
                { width: '200', targets: 0 }
              ]
            });
            // Apply the search
            table.columns().every(function () {
              var that = this;
              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this['value']) {
                  that
                    .search(this['value'])
                    .draw();
                }
              });
            });


          });
        });
      } else if (res.StatusCode = "404") {
        this._methods.hideLoader();
        this.gameDetailList = [];
      }

    });
  }

  downloadGameDetail() {
    let tempgameDetailList = this.gameDetailList;
    for (let i = 0; i < tempgameDetailList.length; i++) {
      delete tempgameDetailList[i]["PId"];
      // delete tempgameDetailList[i]["BeltId"];
      // delete tempgameDetailList[i]["GameId"];
    }
    this.excelService.exportAsExcelFile(tempgameDetailList, 'GameReport');
  }

  //Add New Game Detail
  addGameDetail() {
    if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Create)) {
      this.isGameDetailAdd = true;
      this.isGameDetailEdit = false;
      this.isGameDetailList = false;
      this.pageTitle = "Add New Game Detail";
      this.gameDetail = new GameDetail();
      this.gameDetail.BeltName = "";
      this.gameDetail.IsActive = "true";
      this.getBeltList();
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }


  get f() { return this.GameDetailForm.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.GameDetailForm.invalid) {
      return;
    }
    if (this.isGameDetailAdd == true) {
      if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Create)) {

        this.studentService.InsertGameDetail(this.gameDetail).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.getGameDetailList();
            this.isGameDetailEdit = false;
            this.isGameDetailAdd = false;
            this.pageTitle = "Manage Game Details";
            this.isGameDetailList = true;
            this.toastr.success(res.Message);
          } else if (res.StatusCode === 409) {
            this.toastr.error(res.Message, res.Data);
          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {

          this.toastr.error(error, 'Adding Game Detail Failed!');
        });
      }
      else {
        this.toastr.error("You don't have permission.");
      }
    }
    if (this.isGameDetailEdit == true) {
      if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Update)) {

        this.studentService.UpdateGameDetail(this.gameDetail).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.getGameDetailList();
            this.isGameDetailEdit = false;
            this.isGameDetailAdd = false;
            this.pageTitle = "Update Game Detail info";
            this.isGameDetailList = true;
          } else if (res.StatusCode === 409) {
            this.toastr.error(res.Message, res.Data);
          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {

          this.toastr.error(error, 'Update Game Details Failed!');
        });
      }
      else {
        this.toastr.error("You don't have permission.");
      }
    }
  }

  CancelAdd() {
    this.isGameDetailList = true;
    this.isGameDetailAdd = false;
    this.isGameDetailEdit = false;
    this.pageTitle = "Manage Game Details";
  }

  //Edit Belt
  editGameDetail(item) {
    if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Update)) {
      this.isGameDetailList = false;
      this.isGameDetailAdd = false;
      this.isGameDetailEdit = true;
      this.pageTitle = "Game Detail Edit";
      this.gameDetail = item;
      this.getBeltList();
      if (!isNullOrUndefined(item.BeltId)) {
        this.getGameListByBelt(item.BeltId)
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }

  //Delete Belt
  deleteGameDetail(gameDetail) {
    if (this._methods.CheckUserPermission(ModulesEnum.ManageGameDetails, PermissionEnum.Delete)) {
      if (confirm("Are you sure to inactive this Game Detail")) {
        this.studentService.DeleteGameDetail(gameDetail).subscribe((res: any) => {
          // this.ShowSpinner = false;  
          if (res.StatusCode === 200) {
            this.toastr.success(res.Data);
            this.getGameDetailList();
            //this._router.navigate(['login']);
          } else if (res.statusCode === 409) {
            this.toastr.error(res.message, res.Data);
          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {
          // toaster config --message, title, ToastConfig       
          this.toastr.error(error, 'Game detail deleting Failed!');
        });
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }



}
