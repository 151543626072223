import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { DateRangeFilter } from '../models/DateRangeFilter';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { ExcelService } from '../services/excel.service';
import { BeltGraduation } from '../models/BeltGraduation';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-belt-graduation',
  templateUrl: './belt-graduation.component.html',
  styleUrls: ['./belt-graduation.component.css']
})
export class BeltGraduationComponent implements OnInit {
  beltGraduationData: any;
  dateRangeFilter = new DateRangeFilter();
  date = new Date();
  firstDay =
    new Date(this.date.getFullYear(), this.date.getMonth(), 1);

  lastDay =
    new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  fromoptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    maxDate: this.lastDay,  // Maximal selectable date
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  tooptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    minDate: this.firstDay, // Minimal selectable date
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'searchtoDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  readGraduationReportPermission: boolean = false;
  deleteGraduationReportPermission: boolean = false;
  constructor(public _methods: CommonMethods, public userService: AuthenticationService, private toastr: ToastrService, private _router: Router, private excelService: ExcelService) { }

  ngOnInit() {

    this.dateRangeFilter.StartDate = moment(this.firstDay).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate = moment(this.lastDay).format('MM-DD-YYYY');
    if (this._methods.CheckUserPermission(ModulesEnum.GraduationReport, PermissionEnum.Read)) {
      this.readGraduationReportPermission = true;
      this.getBeltGraduation();
    }
    if (this._methods.CheckUserPermission(ModulesEnum.GraduationReport, PermissionEnum.Delete)) {
      this.deleteGraduationReportPermission = true;
    }
  }

  getBeltGraduation() {
    let table = $('#beltgraduation-table').DataTable({
      "destroy": true,
    });

    table.destroy();
    this._methods.showLoader();
    this.userService.GetBeltGraduations(this.dateRangeFilter).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.beltGraduationData = res.Data;
        var self = this;
        setTimeout(function () {
          $(function () {
            $('#beltgraduation-table').DataTable({
              'columnDefs': [{ type: 'date', 'targets': [3] }],
              'order': [[3, 'desc']],
              "destroy": true,
              "scrollX": true
            });
            $("#beltgraduation-table").on('click', '.deletebeltuphistory', function () {
              var dataString = $(this).attr('id');
              self.deleteBeltupHistory(dataString);
            });
          });
        });
        //this.tempStudentData
      }
      else if (res.StatusCode === 404) {
        this._methods.hideLoader();
        this.beltGraduationData = [];
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }
      else {
        this._methods.hideLoader();
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }

    },
      error => {
        this._methods.hideLoader();
      });
  }

  //Search to get graduation
  getStudentGraduation() {
    this.dateRangeFilter.StartDate = moment(this.dateRangeFilter.StartDate).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate = moment(this.dateRangeFilter.EndDate).format('MM-DD-YYYY');
    this.getBeltGraduation();
  }

  DownloadReport(): void {
    let tempbeltGraduationData = this.beltGraduationData;
    for (let i = 0; i < tempbeltGraduationData.length; i++) {
      if (!isNullOrUndefined(tempbeltGraduationData[i]["BeltVersionNo"]) && tempbeltGraduationData[i]["BeltVersionNo"] != "") {
        tempbeltGraduationData[i]["BeltName"] = tempbeltGraduationData[i]["BeltName"] + " - " + tempbeltGraduationData[i]["BeltVersionNo"];
      }
      if (!isNullOrUndefined(tempbeltGraduationData[i]["PriorBeltVersionNo"]) && tempbeltGraduationData[i]["PriorBeltVersionNo"] != "") {
        tempbeltGraduationData[i]["PriorBeltName"] = tempbeltGraduationData[i]["PriorBeltName"] + " - " + tempbeltGraduationData[i]["PriorBeltVersionNo"];
      }
      delete tempbeltGraduationData[i]["PriorBeltVersionNo"];
      delete tempbeltGraduationData[i]["BeltVersionNo"];
      delete tempbeltGraduationData[i]['BeltUpId'];
      delete tempbeltGraduationData[i]['BeltId'];
      delete tempbeltGraduationData[i]['SenseiId'];
      delete tempbeltGraduationData[i]['StudentId'];
      delete tempbeltGraduationData[i]['PriorBeltId'];

    }
    if (tempbeltGraduationData.length > 0) {
      this.excelService.exportAsExcelFile(tempbeltGraduationData.sort(this.custom_sort), 'GraduationReport');
      this.getBeltGraduation();
    }

  }

  custom_sort(a, b) {
    return new Date(b.BeltUpDate).getTime() - new Date(a.BeltUpDate).getTime();
  }

  //from date change event
  fromchange(event) {
    this.tooptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      minDate: moment(this.dateRangeFilter.StartDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'searchtoDate',
      useEmptyBarTitle: false,
    }

  }
  tochange(event) {
    this.fromoptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      // minDate: this.dateRangeFilter.StartDate,
      maxDate: moment(this.dateRangeFilter.EndDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {},
      fieldId: 'searchFromDate',
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    };
  }

  deleteBeltupHistory(item) {
    if (this._methods.CheckUserPermission(ModulesEnum.GraduationReport, PermissionEnum.Delete)) {
      if (confirm("Are you sure to delete this item?")) {
        this.userService.DeleteBeltUpHistory(item).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.toastr.success(res.Data);
            let table = $('#beltgraduation-table').DataTable({
              'columnDefs': [{ type: 'date', 'targets': [3] }],
              'order': [[3, 'desc']],
              "destroy": true,
              "scrollX": true
            });
            table.destroy();

            this.getBeltGraduation();
          }
          else if (res.StatusCode === 404) {
            this.toastr.error(res.Message, res.Data);
          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {

          this.toastr.error(error, 'Deleting BeltUp History Failed!');
        });
      }
    }
    else
    {
      this.toastr.error("You don't have permission.");
    }
  }
}
