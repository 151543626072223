export class DailyUpdate
{
    Comment:any;
    RedCoin:any;
    BlueCoin:any;
    GreenCoin:any;
    YellowCoin:any;
    CreatedBy:any;
    CreatedDate:any;
    CreaterName:any;
}