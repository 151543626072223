import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '../../node_modules/@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentHistoryComponent } from './student-history/student-history.component';
import { AddstudentComponent } from './addstudent/addstudent.component';
import { RegistrationComponent } from './registration/registration.component';
import { AuthenticationService } from './services/authentication.service';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonMethods } from '../app/helper/Method';
import { ImportStudentComponent } from './import-student/import-student.component';
import { StudentListComponent } from './student-list/student-list.component';
import { DataTablesModule } from 'angular-datatables';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StudentGraphComponent } from './student-graph/student-graph.component';
import { StudentActivityReportComponent } from './student-activity-report/student-activity-report.component';
import { BeltupComponent } from './beltup/beltup.component';
import { DailyUpdateComponent } from './daily-update/daily-update.component';
import { BeltListComponent } from './belt-list/belt-list.component';
import { BeltGraduationComponent } from './belt-graduation/belt-graduation.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { ExcelService } from './services/excel.service';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { NinjaUploadHistoryComponent } from './ninja-upload-history/ninja-upload-history.component';
import { NinjaActivityHistoryComponent } from './ninja-activity-history/ninja-activity-history.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { GameListComponent } from './game-list/game-list.component';
import { GameAddComponent } from './game-add/game-add.component';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { NinjaInfoComponent } from './ninja-info/ninja-info.component';
import { NinjaDailyLogComponent } from './ninja-daily-log/ninja-daily-log.component';
import { CenterLoginComponent } from './center-login/center-login.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { ProgramReportsComponent } from './program-reports/program-reports.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    StudentHistoryComponent,
    AddstudentComponent,
    RegistrationComponent,
    HeaderComponent,
    SidebarComponent,
    ImportStudentComponent,
    StudentListComponent,
    StudentGraphComponent,
    StudentActivityReportComponent,
    BeltupComponent,
    DailyUpdateComponent,
    BeltListComponent,
    BeltGraduationComponent,
    DailyReportComponent,
    NinjaUploadHistoryComponent,
    NinjaActivityHistoryComponent,
    UserListComponent,
    UserEditComponent,
    GameListComponent,
    GameAddComponent,
    NinjaInfoComponent,
    NinjaDailyLogComponent,
    CenterLoginComponent,
    FeedbackComponent,
    GameDetailsComponent,
    ProgramReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      closeButton: true
    }),
    Ng2SearchPipeModule,
    DataTablesModule,
    NgDatepickerModule
  ],
  providers: [AuthenticationService, CommonMethods, ExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
