export class GameDetail {

    PId: any;
    GameId: any;
    GameName: any;
    BeltId: any;
    BeltName: any;

    Description: any;
    CommandsLearnt: any;
    ReviewData: any;
    KnownGdpIssues: any;

    CreatedBy: any;
    CreatedDate: any;
    ModifiedBy: any;
    ModifiedDate: any;
    IsActive: any;
    CenterId: any;

}