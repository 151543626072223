import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { DailyUpdate } from '../models/DailyUpdate';
import { Router } from '@angular/router';
import { ExcelService } from '../services/excel.service';
import { DateRangeFilter } from '../models/DateRangeFilter';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { StudentHistory } from '../models/studenthistory';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-ninja-daily-log',
  templateUrl: './ninja-daily-log.component.html',
  styleUrls: ['./ninja-daily-log.component.css']
})
export class NinjaDailyLogComponent implements OnInit {
  isNinjaList: boolean = true;
  isNinjaEdit: boolean = false;
  ninjaTitle: any = "Ninja Daily log";
  studentHistory = new StudentHistory();
  studentHistoryForm: FormGroup;
  studentHistoryData: any = [];
  beltList: any = [];
  gameList: any = [];
  submitted = false;
  rating: number;
  currentUser;
  dateRangeFilter = new DateRangeFilter();
  historystarList: boolean[] = [true, true, true];
  date = new Date();
  firstDay =
    new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  lastDay = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  fromoptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    maxDate: this.lastDay,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY',
    addClass: 'form-control',
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false,
  };
  tooptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    minDate: this.firstDay,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY',
    addClass: 'form-control',
    addStyle: {},
    fieldId: 'searchtoDate',
    useEmptyBarTitle: false,
  };
  NinjaEditPermission: boolean = false;
  NinjaDeletePermission: boolean = false;
  NinjaReadPermisssion: boolean = false;
  constructor(private _methods: CommonMethods, private formBuilder: FormBuilder, public studentService: StudentService, private toastr: ToastrService, private _router: Router, private excelService: ExcelService) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);

    //Check Read Permission
    if (!this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Read)) {
      this.toastr.error("You don't have permission.");
      setTimeout(() => {
        this._methods.logOut();
      }, 2000);
      return;
    }
  }

  ngOnInit() {
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Update)) {
      this.NinjaEditPermission = true;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Delete)) {
      this.NinjaDeletePermission = true;
    }
    this.studentHistoryForm = this.formBuilder.group({
      BeltId: ['', Validators.required],
      GameId: ['', Validators.required],
      Grade: [''],
      Comment: [''],
    });
    this.dateRangeFilter.StartDate = moment(this.firstDay).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate = moment(this.lastDay).format('MM-DD-YYYY');
    this.getStudentHistory();
  }
  get f() { return this.studentHistoryForm.controls; }
  getStudentHistory() {
    let table = $('#dailylog-table').DataTable({
      "destroy": true,
    });

    table.destroy();
    this._methods.showLoader();
    this.studentService.GetStudentHistoryByDate(this.dateRangeFilter).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.studentHistoryData = res.Data;
        setTimeout(function () {
          $(function () {
            $('#dailylog-table').DataTable({
              'order': [[6, 'asc']],
              'destroy': true
            });
          });
        });
      }
      else if (res.StatusCode === 404) {
        this._methods.hideLoader();
        this.studentHistoryData = [];

      }
      else if (res.StatusCode === 400) {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
        this.studentHistoryData = [];
      }
      else {
        this._methods.hideLoader();
        this.studentHistoryData = [];
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {
      this._methods.hideLoader();
      this.toastr.error(error, 'Cannot get Ninja history!');
    });
  }


  deleteDailyUpdate(item) {
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Delete)) {
      if (confirm("Are you sure to delete this item?")) {
        this.studentService.DeleteStudentHistory(item.StudentHistoryId).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.toastr.success(res.Data);
            let table = $('#dailylog-table').DataTable({
              'order': [[6, 'asc']], 'destroy': true
            });

            table.destroy();
            this.getStudentHistory();
          }
          else if (res.StatusCode === 404) {
            this.studentHistoryData = [];

          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {

          this.toastr.error(error, 'Deleting DailyUpdate Failed!');
        });
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }

  //export to excel 
  downloadReport() {
    let tempdailylist = this.studentHistoryData;
    for (let i = 0; i < tempdailylist.length; i++) {
      delete tempdailylist[i]["StudentHistoryId"];
      delete tempdailylist[i]["StudentId"];
      delete tempdailylist[i]["BeltId"];
      delete tempdailylist[i]["GameId"];
      delete tempdailylist[i]["SpentHours"];
      delete tempdailylist[i]["CreatedBy"];
      delete tempdailylist[i]["ModifiedBy"];
      delete tempdailylist[i]["ModifiedDate"];
    }
    this.excelService.exportAsExcelFile(tempdailylist, 'DailyReport');
  }

  //Search to get graduation
  searchGetDailyUpdate() {
    this.dateRangeFilter.StartDate = moment(this.dateRangeFilter.StartDate).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate = moment(this.dateRangeFilter.EndDate).format('MM-DD-YYYY');
    this.getStudentHistory();
  }

  //from date change event
  fromchange(event) {
    this.tooptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      minDate: moment(this.dateRangeFilter.StartDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'searchtoDate',
      useEmptyBarTitle: false,
    }

  }
  tochange(event) {
    this.fromoptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      // minDate: this.dateRangeFilter.StartDate,
      maxDate: moment(this.dateRangeFilter.EndDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {},
      fieldId: 'searchFromDate',
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    };
  }

  editStudentHistory(item) {
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Update)) {
      this.ninjaTitle = "Edit Ninja Daily Log";
      this.isNinjaEdit = true;
      this.isNinjaList = false;
      this.studentHistory = item;
      this.getBeltList();
      if (!isNullOrUndefined(item.BeltId)) {
        this.getGameListByBelt(item.BeltId)
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }
  getBeltList() {
    this.beltList = [];
    this.studentService.GetBeltList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
        if (this.studentHistory.BeltId == "" && this.beltList.length > 0) {
          this.studentHistory.BeltId = this.beltList["0"].BeltId;
        }
      }

    });
  }
  getGameList() {
    this.gameList = [];
    this.studentService.GetGameList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }

  getGameListByBelt(beltId) {
    this.gameList = [];
    this.studentService.GetGameListByBeltId(beltId).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }
  CancelAdd() {
    this.ninjaTitle = "Ninja Daily Log";
    this.isNinjaEdit = false;
    this.isNinjaList = true;
    this.studentHistory = new StudentHistory;
  }

  beltChangeEvent() {
    if (this.studentHistory.BeltId > 0) {

      this.getGameListByBelt(this.studentHistory.BeltId);
      setTimeout(() => {
        this.studentHistory.GameId = "";
      }, 500);
    }

  }

  setStar(data: any) {
    this.studentHistory.Grade = data + 1;
  }
  onSubmit() {
    this.submitted = true;
    if (this.studentHistoryForm.invalid) {
      return;
    }
    if (this.isNinjaEdit) {
      this.studentHistory.ModifiedBy = this.currentUser.UserID;
      this.UpdateStudentHistory();
    }
  }

  UpdateStudentHistory() {
    this.studentService.UpdateStudentHistory(this.studentHistory).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this.isNinjaEdit = false;
        this.isNinjaList = true;
        this.ninjaTitle = "Ninja Daily Log";
        this.getStudentHistory();
      }
      else if (res.StatusCode === 404) {
        this.toastr.success(res.Data);
        this.studentHistoryData = [];
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Editing of DailyUpdate Failed!');
    });
  }
}
