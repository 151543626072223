import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
senseiList:any;
readUserListPermission:Boolean=false;
editUserPermission:Boolean=false;
  constructor(private _methods:CommonMethods, private authenticationService:AuthenticationService,private _router:Router) { }

  ngOnInit() {
    if(this._methods.CheckUserPermission(ModulesEnum.ManageUser, PermissionEnum.Read))
    {
      this.readUserListPermission=true;
      this.getSenseiList();
    }
    if(this._methods.CheckUserPermission(ModulesEnum.ManageUser, PermissionEnum.Update))
    {
      this.editUserPermission=true;
    }
  }

  getSenseiList()
  {
    this.authenticationService.GetSenseiList().subscribe((res: any) => {   
      // console.log(res);
      if (res.StatusCode === 200) 
      {
        this.senseiList=res.Data;
        setTimeout(function () {
          $(function () {
            $('#users-table').DataTable({
              'order': [[0, 'asc']],
              'destroy':true
            });
          });
        });
        
      }
      else if (res.StatusCode === 404) 
      {
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }
      else 
      {
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }
     
    },
    error => {
    
    });
  }

  editSensei(id)
  {
    if(this._methods.CheckUserPermission(ModulesEnum.ManageUser, PermissionEnum.Update))
    {
    localStorage.removeItem("SenseiId");
    localStorage.setItem("SenseiId",btoa(id));
    this._router.navigate(['edituser']);
    }
  }
}
