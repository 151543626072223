import { Component,OnInit,ChangeDetectorRef } from '@angular/core';
import { CommonMethods } from '../app/helper/Method';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'CodeNinjaApp';
  constructor(public _methods: CommonMethods,private cdref: ChangeDetectorRef ){
    
  }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    
    this.cdref.detectChanges();
    
     }
}
