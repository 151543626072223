import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonMethods } from '../helper/Method';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any =
    {
      Email: '',
      Password: ''
    };
  userCenter=[];
  constructor(private authenticationService: AuthenticationService, private toastr: ToastrService, private _router: Router, private _method: CommonMethods) { 
   localStorage.clear();
   sessionStorage.clear();
  }

  ngOnInit() {
  }

  Login() {
    this._method.showLoader();
    this.authenticationService.login(this.form).pipe(first())
      .subscribe(
        res => {
          if (res.StatusCode === 200) {
           this._method.hideLoader();
            this._router.navigate([`centerlogin/${btoa(res.Data.UserID)}`])
           }
          else if (res.StatusCode === 404) {
            this._method.hideLoader();
            this.toastr.error(res.Message, res.Data, { closeButton: true });
          }
          else {
            this._method.hideLoader();
            this.toastr.error(res.Message, res.Data, { closeButton: true });
          }

        },
        error => {
          this._method.hideLoader();
        });
  }

  Register() {
    this._router.navigate(['/register']);
  }

  getUserCenter(userId){
    this.authenticationService.GetUserCenter(userId).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.userCenter=res.Data;
        }else if (res.StatusCode === 404) {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }
        else {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }

      },
      error => {

      })
  }

  ConfirmOkClick(){
    this.updateUserCenter(1);
  }
  updateUserCenter(centerId){
    this.authenticationService.UpdateUserLogInCenter(centerId).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.userCenter=res.Data;
        }else if (res.StatusCode === 404) {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }
        else {
          this.toastr.error(res.Message, res.Data, { closeButton: true });
        }

      },
      error => {

      })
  }

}
