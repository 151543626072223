import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {
  studentData: any;
  tempStudentData: any;
  studentStatus: boolean = true;
  currentuser: any;
  isCreateStudent:boolean=false;
  isEditStudent:boolean=false;
  isDeleteStudent:boolean=false;
  constructor(private _methods:CommonMethods, private studentService: StudentService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit() {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentuser = JSON.parse(user);

    localStorage.removeItem("StudentId");
    this.getStudent();
    if (this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Create)) {    
      this.isCreateStudent=true;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Update)) {    
      this.isEditStudent=true;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Delete)) {    
      this.isDeleteStudent=true;
    }

  }

  getStudent() {
    if (this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Read)) {     
  this._methods.showLoader();
    this.studentService.StudentList().subscribe((res: any) => {
      // console.log(res);
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.studentData = res.Data;
        this.studentData = this.studentData.filter(a => a.Status == this.studentStatus);
        setTimeout(function () {
          $(function () {
            $('#student-table').DataTable({
              'order': [[0, 'asc']],
              'destroy': true
            });
          });
        });
        //this.tempStudentData
      }
      else if (res.StatusCode === 404) {
        this._methods.hideLoader();
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }
      else {
        this._methods.hideLoader();
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }

    },
      error => {
        this._methods.hideLoader();
      });
    }
    else
    {
      this.toastr.error("You dont have permission.", "", { closeButton: true });
    }
  }

  addStudent(): void {
    localStorage.removeItem("StudentId");
    this._router.navigate(['addstudent']);
  };

  editStudent(id) {
    if (this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Update)) {    
         localStorage.removeItem("StudentId");
        localStorage.setItem("StudentId", btoa(id));
        this._router.navigate(['addstudent']);
    }
    else
    {
      this._methods.logOut();
    }
  }

  //Delete Student
  deleteStudent(studentId) {
  if (this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Delete)) { 
    if (confirm("Are you sure to inactive this student")) {
      this.studentService.DeleteStudent(studentId).subscribe((res: any) => {
        // this.ShowSpinner = false;  
        if (res.StatusCode === 200) {
          this.toastr.success(res.Data);
          let table = $('#student-table').DataTable({
            'order': [[0, 'asc']], 'destroy': true
          });

          table.destroy();
          this.getStudent();
          //this._router.navigate(['login']);
        } else if (res.statusCode === 409) {
          this.toastr.error(res.message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {
        // toaster config --message, title, ToastConfig       
        this.toastr.error(error, 'Deleting Student Failed!');
        //console.log(error);
      });
    }
  }else
  {
    this._methods.logOut();
  }
  }

  txtsearchinput(value) {

    if (value.trim().length > 0) {

    }
  }

  onStatusChange(status) {
    let table = $('#student-table').DataTable({
      'order': [[0, 'asc']], 'destroy': true
    });

    table.destroy();

    this.studentService.StudentList().subscribe((res: any) => {
      // console.log(res);
      if (res.StatusCode === 200) {
        this.studentData = res.Data;

        this.studentData = this.studentData.filter(a => a.Status == status);
        setTimeout(function () {
          $(function () {
            $('#student-table').DataTable({
              'order': [[0, 'asc']], 'destroy': true
            });
          });
        }, 500);
        //this.tempStudentData
      }
      else if (res.StatusCode === 404) {
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }
      else {
        //this.toastr.error(res.message, res.data, { closeButton: true });
      }

    },
      error => {

      });
  }
}
