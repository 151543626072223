export class User {  
    FirstName:any;
    LastName:any;
    UserName:any;
    Password:any;
    RoleId:any;
    RoleName:any;
    CreatedBy:any;
    CreatedDate:any;
    ModifiedBy:any;
    ModifiedDate:any;
    userID:any;
    IsActive:any;
    userToken:any;
    Token:any;
    UserCenter:any;
}