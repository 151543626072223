import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, NgForm } from '@angular/forms';
import { StudentHistory } from '../models/studenthistory';
import { CommonMethods } from '../helper/Method';
import { isNullOrUndefined } from 'util';
import { RecentStudent } from '../models/RecentStudent';
import { Router } from '@angular/router';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-student-history',
  templateUrl: './student-history.component.html',
  styleUrls: ['./student-history.component.css']
})
export class StudentHistoryComponent implements OnInit {
  currentUser;
  studentStatus: any = true;
  studentData: any = [];
  SelectStudentId: any;
  StudentSessionForm: FormGroup;
  studentHistoryData: any = [];
  StudentSessionData: any;
  starList: boolean[] = [true, true, true];
  historystarList: boolean[] = [true, true, true];
  rating: number;
  beltList: any = [];
  gameList: any = [];
  gameDetailList: any = [];
  _gameDetailsBeltIdSelected: any;

  gameDetailGameList: any = [];
  gameDetailBelList: any = [];
  gameDetailShowList: any = [];
  showGameDetails: boolean = false;

  StudentCurrentData = new StudentHistory();
  IsCompleted: boolean = false;
  IsRated: boolean = true;
  keepBlur: boolean = false;
  f: any;
  saveClicked = false;
  logClicked = false;
  missedSessionClicked = false;
  recentStudentList = new RecentStudent;
  readStudentHistoryPermission: boolean = false;
  addStudentHistoryPermission: boolean = false;
  preCommentText = '';
  constructor(private _methods: CommonMethods, private studentService: StudentService, private toastr: ToastrService, private formBuilder: FormBuilder, private _method: CommonMethods, private _router: Router) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);
  }

  ngOnInit() {
    if (this._methods.CheckUserPermission(ModulesEnum.LogNinjaActivity, PermissionEnum.Read)) {
      this.readStudentHistoryPermission = true;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.LogNinjaActivity, PermissionEnum.Create)) {
      this.addStudentHistoryPermission = true;
    }
    if (!isNullOrUndefined(sessionStorage.getItem("recentstudentlist"))) {

      this.recentStudentList = JSON.parse(sessionStorage.getItem("recentstudentlist"));
      let currentdate = new Date();
      currentdate.setHours(0, 0, 0, 0)
      let sessiondate = new Date(this.recentStudentList.RecentCreatedDate);
      sessiondate.setHours(0, 0, 0, 0);
      if (sessiondate < currentdate) {
        this.recentStudentList.Data = [];
        sessionStorage.removeItem("recentstudentlist");
      }
    }

    // read all game details table data
    this.getGameDetailList();
  }
  setStar(data: any) {
    this.IsRated = true;
    this.rating = data + 1;
    for (var i = 0; i <= 1; i++) {
      if (i <= data) {
        this.starList[i] = false;
      }
      else {
        this.starList[i] = true;
      }
    }
  }
  GameComplete(rating) {
    //document.getElementById("AllocatedBusBtnConfirmation").click();
    this.rating = rating;
    this.logClicked = false;
    //this.ConfirmOkClick(rating);
  }
  onSubmit(f, event) {
    if (this.saveClicked) {
      this.saveClicked = false;
      this.StudentCurrentData.ModifiedBy = this.currentUser.UserID;
      this.studentService.SaveComment(this.StudentCurrentData).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.toastr.success(res.Message);
          this.getStudentDetail(this.SelectStudentId);
        }
        else if (res.StatusCode === 409) {
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {

        this.toastr.error(error, 'Saving comment failed!');
      });
    }
    else if (this.logClicked) {
      this.StudentCurrentData.Grade = 0;
      this.StudentCurrentData.Spenthours = 0;
      this.StudentCurrentData.CreatedBy = this.currentUser.UserID;
      this.studentService.LogStudentHistory(this.StudentCurrentData).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this.toastr.success(res.Data);
          this.getStudentDetail(this.SelectStudentId);
        }
        else if (res.StatusCode === 409) {
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {

        this.toastr.error(error, 'Saving Student History Failed!');
      });
    }
    else if (this.missedSessionClicked) {
      this.missedSessionClicked = false;
      this.StudentCurrentData.Grade = 0;
      this.StudentCurrentData.Spenthours = 0;
      this.StudentCurrentData.CreatedBy = this.currentUser.UserID;

      this._methods.showLoader();
      this.studentService.MissedSessionLog(this.StudentCurrentData).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this._methods.hideLoader();
          this.toastr.success(res.Data);
          this.getStudentDetail(this.SelectStudentId);
        }
        else if (res.StatusCode === 409) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {
        this._methods.hideLoader();
        this.toastr.error(error, 'Saving student history failed!');
      });
    }
    else {
      this.ConfirmOkClick(this.rating);
      //document.getElementById("AllocatedBusBtnConfirmation").click();
      //f.submitted = false;
    }

  }

  onSaveClick() {
    this.saveClicked = true;
  }


  onLogClick() {
    this.logClicked = true;
  }
  onMissedSessionClick() {
    this.StudentCurrentData.Comment = "Ninja missed this session";
    this.missedSessionClicked = true;
  }
  //Student Search
  SearchStudent(event) {
    let name = event.target.value;
    if (name.trim().length > 0) {
      this.getStudentByName(name);
    }
    else {
      this.studentData = [];
    }

  }

  //Get Student By Name
  getStudentByName(name) {
    this.studentService.StudentListByName(name, this.studentStatus).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.studentData = res.Data;
      } else if (res.StatusCode === 409) {
        this.studentData = [];
        //this.toastr.error(res.Message,res.Data);
      }
      else if (res.StatusCode === 400) {
        this.studentData = [];
        //this.toastr.error(res.Message,res.Data);
      }
      else {
        this.studentData = [];
        // this.toastr.error(res.Message,res.Data);
      }
    }, error => {
      // toaster config --message, title, ToastConfig       
      this.toastr.error(error, 'Registration Failed!');
      //console.log(error);
    });

  }

  CheckStduentSession(student) {

    if (this.keepBlur && this.StudentCurrentData.Comment.trim().length > 0) {
      this.keepBlur = false;
      this.autoSaveOnAway();
    }
    this.InSertOldCommentInStudentHistory();
    setTimeout(() => {
      this.SelectStudentId = student.StudentId;
      if (this.recentStudentList.Data.length > 0) {
        let stud = this.recentStudentList.Data.filter(x => x.StudentId == this.SelectStudentId);
        if (isNullOrUndefined(stud) || stud.length == 0) {
          this.recentStudentList.Data.push(student);
          this.recentStudentList.RecentCreatedDate = new Date();
          let item = JSON.stringify(this.recentStudentList)
          sessionStorage.setItem("recentstudentlist", JSON.stringify(this.recentStudentList));
        }
      }
      else {
        this.recentStudentList.Data.push(student);
        this.recentStudentList.RecentCreatedDate = new Date();
        let item = JSON.stringify(this.recentStudentList)
        sessionStorage.setItem("recentstudentlist", JSON.stringify(this.recentStudentList));
      }

      this.getStudentDetail(student.StudentId);
    }, 500);


  }

  GetStudentHistoryById(studentId) {
    this.studentService.StudentHistoryById(studentId).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.studentHistoryData = res.Data;
      } else if (res.StatusCode === 409) {
        this.studentHistoryData = [];
        //this.toastr.error(res.Message,res.Data);
      }
      else if (res.StatusCode === 400) {
        this.studentHistoryData = [];
        //this.toastr.error(res.Message,res.Data);
      }
      else {
        this.studentHistoryData = [];
        // this.toastr.error(res.Message,res.Data);
      }
    }, error => {
      // toaster config --message, title, ToastConfig       
      this.toastr.error(error, 'Reading student history failed!');
      //console.log(error);
    });
  }

  getStudentDetail(studentId) {

    this.studentService.GetStudentById(studentId).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.StudentSessionData = res.Data;
        this.StudentSessionData.StatusText = this.StudentSessionData.Status == true ? "Active" : "In active";
        this.StudentCurrentData.BeltId = this.StudentSessionData.BeltId == 0 ? "" : this.StudentSessionData.BeltId;
        this.StudentCurrentData.GameId = this.StudentSessionData.GameId == 0 ? "" : this.StudentSessionData.GameId;
        this.StudentCurrentData.StudentId = this.StudentSessionData.StudentId;
        this.StudentCurrentData.Comment = (isNullOrUndefined(this.StudentSessionData.Comment) || this.StudentSessionData.Comment == "") ? this.preCommentText : this.StudentSessionData.Comment;
        this.StudentCurrentData.ModifiedDate = isNullOrUndefined(this.StudentSessionData.ModifiedDate) ? this.StudentSessionData.CreatedDate : this.StudentSessionData.ModifiedDate;
        this.StudentCurrentData.TeacherName = isNullOrUndefined(this.StudentSessionData.TeacherName) ? "" : this.StudentSessionData.TeacherName;
        this.getBeltList();
        if (this.StudentCurrentData.BeltId > 0) {
          this.getGameListByBelt(this.StudentCurrentData.BeltId);
        }
        else {
          this.getGameList();
        }

        this.GetStudentHistoryById(this.StudentSessionData.StudentId);
      }
      else if (res.StatusCode === 409) {
        this.StudentSessionData = null;
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.StudentSessionData = null;
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.StudentSessionData = null;
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {
      // toaster config --message, title, ToastConfig       
      this.toastr.error(error, 'Reading Student Details Failed!');
      //console.log(error);
    });
  }

  getBeltList() {
    this.beltList = [];
    this.studentService.GetBeltList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
        if (this.StudentCurrentData.BeltId == "" && this.beltList.length > 0) {
          this.StudentCurrentData.BeltId = this.beltList["0"].BeltId;
        }
      }

    });
  }
  getGameList() {
    this.gameList = [];
    this.studentService.GetGameList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }

  getGameListByBelt(beltId) {
    this.gameList = [];
    this.studentService.GetGameListByBeltId(beltId).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }

  ConfirmCancelClick() {
    document.getElementById("AllocatedBusBtnConfirmation").click();
  }

  ConfirmOkClick(rating) {
    //if (this.rating > 0) {
    this.StudentCurrentData.Grade = rating;
    this.StudentCurrentData.CreatedBy = this.currentUser.UserID;
    this.studentService.SaveStudentHistory(this.StudentCurrentData).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Message);
        this.IsCompleted = true;
        this.keepBlur = false;
        //document.getElementById("AllocatedBusBtnConfirmation").click();
        this.StudentCurrentData = new StudentHistory();
        this.starList = [true, true, true];
        this.rating = 0;
        this.getStudentDetail(this.SelectStudentId);
      }
      else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Save StudentHistory Failed!');
    });
    // }

    // else {
    //   this.IsRated = false;
    // }
  }

  autoSaveOnAway() {
    this.StudentCurrentData.CreatedBy = this.currentUser.UserID;
    this.studentService.SaveStudentHistory(this.StudentCurrentData).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this.IsCompleted = true;
        this.StudentCurrentData = new StudentHistory();
        this.getStudentDetail(this.SelectStudentId);
      }
      else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Save StudentHistory Failed!');
    });
  }
  txtcommentblur() {
    this.keepBlur = true;
  }

  checkIsNumber(event) {
    this._method.isNumber(event);
  }

  beltChangeEvent() {
    if (this.StudentCurrentData.BeltId > 0) {
      this.getGameListByBelt(this.StudentCurrentData.BeltId);
    }

  }
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  //clear recent student
  ClearRecentStudent() {
    sessionStorage.removeItem("recentstudentlist");
    this.recentStudentList.Data = [];
  }

  //Log old comment into studenthistory
  InSertOldCommentInStudentHistory() {
    this.studentService.SaveOldCommentInStudentHistory().subscribe((res: any) => {

    });
  }

  goToNinjaInfo(studentId) {
    this._router.navigate([`ninjainfo/${btoa(studentId)}`]);
  }


  // get Game details 

  //get games for a belt
  getGameDetailList() {
    this.gameDetailList = [];
    this.studentService.GetAllGameDetails().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameDetailList = res.Data.filter(d => d.IsActive == true);
        this.gameDetailBelList = res.Data.filter((thing, i, arr) => arr.findIndex(t => t.BeltId == thing.BeltId) == i);
      }
    });
  }

  //belt on change event
  gameDetailsGetBeltChangeEvent(value: any) {
    this.gameDetailsBGetGameListByBelt(value);
  }

  //get games for a belt
  gameDetailsBGetGameListByBelt(_beltId) {
    //debugger;
    this.gameDetailGameList = this.gameDetailList.filter(a => a.BeltId == _beltId);
    this._gameDetailsBeltIdSelected = _beltId;
  }
  getGameDetailsForThisGame(_gameID) {
    this.showGameDetails = true;
    this.gameDetailShowList = [];
    this.gameDetailShowList = this.gameDetailList.filter(a => a.GameId == _gameID && a.BeltId == this._gameDetailsBeltIdSelected);
    // debugger;
  }
}
