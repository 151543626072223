export class Student {

    StudentId: any;
    FirstName: any;
    DateOfBirth: any;
    LastName: any;
    AKAName: any;
    Belt: any;
    BeltId: any;
    GameId: any;
    Game: any;
    BeltVersionId: any;
    BeltVersionNo: any;
    Status: any;
    CreatedBy: any;
    CreatedDate: any;
    ModifiedBy: any;
    ModifiedDate: any;

    BucksEarned: any;

    NinjaBackground: any;
    LastParentReviewDate: any;
    NextParentReviewDate: any;

}