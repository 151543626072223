import { Component, OnInit } from '@angular/core';
import { CommonMethods } from '../helper/Method';
import { ActivatedRoute } from "@angular/router";
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currentuser: any;
  showmanage: boolean = false;
  showReport: boolean = false;
  showMaintain: boolean = false;
  showUpdate: boolean = false;
  showGovern: boolean = false;
  //Manage Permission
  readNinjaActivity: boolean = false;
  readManageNinjaInfo: boolean = false;
  readBeltup: boolean = false;
  readSensaiDailyLog: boolean = false;

  //Report Permission
  reportNinjaDailLog: boolean = false;
  reportSensaiDailyLog: boolean = false;
  reportNinjaScanReport: boolean = false;
  reportGraduation: boolean = false;
  reportProgram: boolean = false;

  //Maintain
  maintainGameInfo: boolean = false;
  maintainUpdateBeltInfo: boolean = false;
  maintainManageUser: boolean = false;

  //Update Permission
  updateCustomerInfo: boolean = false;
  updateNinjaScanInfo: boolean = false;

  //Govern
  governRegister: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, public _methods: CommonMethods) { }

  ngOnInit() {
    //Manage Permission
    this.readNinjaActivity = this._methods.CheckUserPermission(ModulesEnum.LogNinjaActivity, PermissionEnum.Read);
    this.readManageNinjaInfo = this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Read);
    this.readBeltup = this._methods.CheckUserPermission(ModulesEnum.Beltup, PermissionEnum.Read);
    this.readSensaiDailyLog = this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Create);

    //Report Permission
    this.reportNinjaDailLog = this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Read);
    this.reportSensaiDailyLog = this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Read);
    this.reportNinjaScanReport = this._methods.CheckUserPermission(ModulesEnum.NinjaScanReport, PermissionEnum.Read);
    this.reportGraduation = this._methods.CheckUserPermission(ModulesEnum.GraduationReport, PermissionEnum.Read);
    this.reportProgram = this._methods.CheckUserPermission(ModulesEnum.ManageProgramReports, PermissionEnum.Read);

    //Maintain Permission
    this.maintainGameInfo = this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Read);
    this.maintainUpdateBeltInfo = this._methods.CheckUserPermission(ModulesEnum.UpdateBeltInfo, PermissionEnum.Read);
    this.maintainManageUser = this._methods.CheckUserPermission(ModulesEnum.ManageUser, PermissionEnum.Read);

    //Update Permission
    this.updateCustomerInfo = this._methods.CheckUserPermission(ModulesEnum.UploadCustomerInfo, PermissionEnum.Read);
    this.updateNinjaScanInfo = this._methods.CheckUserPermission(ModulesEnum.UploadNinjaScanInfo, PermissionEnum.Read);

    //Govern Permission
    this.governRegister = this._methods.CheckUserPermission(ModulesEnum.RegisterUser, PermissionEnum.Read);
    if (this.readNinjaActivity || this.readManageNinjaInfo || this.readBeltup || this.readSensaiDailyLog) {
      this.showmanage = true;
    }
    if (this.reportNinjaDailLog || this.reportSensaiDailyLog || this.reportNinjaScanReport || this.reportGraduation || this.reportProgram) {
      this.showReport = true;
    }
    if (this.maintainGameInfo || this.maintainUpdateBeltInfo || this.maintainManageUser) {
      this.showMaintain = true;
    }
    if (this.updateCustomerInfo || this.updateNinjaScanInfo) {
      this.showUpdate = true;
    }
    if (this.governRegister) {
      this.showGovern = true;
    }
    const user = atob(sessionStorage.getItem('authData'));
    this.currentuser = JSON.parse(user);
  }


}
