import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { DateRangeFilter } from '../models/DateRangeFilter';
import { AppConstant } from '../helper/app.constants';
import { DailyUpdate } from '../models/DailyUpdate';
import { FeedBack } from '../models/FeedBack';
import { isNullOrUndefined } from 'util';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    url: string = AppConstant.Base_Url + '/User';
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(isNullOrUndefined(localStorage.getItem('userlogin')) ? User  : JSON.parse(atob(localStorage.getItem('userlogin'))));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(userData: User) {
        return this.http.post<any>(`${this.url}/Authenticate`, userData)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.Token) {
                    user.Data.userToken = user.Token;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                     localStorage.setItem('userlogin', btoa(JSON.stringify(user.Data)));
                                   
                    this.currentUserSubject.next(user.Data);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        sessionStorage.clear();
        this.currentUserSubject.next(null);
    }

    // user signup
    userSignup(userData: User) {
        return this.http.post(this.url + '/Register', userData);
    }

    //get belt graduation list
    GetBeltGraduations(dateRangeFilter) {
        // console.log(userData); 
        return this.http.post(this.url + '/GetBeltGraduations', dateRangeFilter);
    }

    //get belt graduation list
    GetAllDailyUpdate(dateRangeFilter) {
        // console.log(userData); 
        return this.http.post(this.url + '/GatAllDailyUpdate', dateRangeFilter);
    }

    //get sensei list
    GetSenseiList() {
        // console.log(userData); 
        return this.http.post(this.url + '/GetSenseiList', "");
    }

    //update sensei detail
    UpdateUser(user: User) {
        // console.log(userData); 
        return this.http.post(this.url + '/UpdateUser', user);
    }

    //get sensei detail by id
    GetUserById(userId: any) {
        return this.http.get(this.url + '/GetUserById?userId=' + userId);
    }

    //check user exists
    UserExists(user: User) {
        // console.log(userData); 
        return this.http.post(this.url + '/UserExists', user);
    }

    //check user exists
    CheckUserExists(user: User) {
        return this.http.post(this.url + '/UserExists', user);
    }

    // get list of role
    getRoleList() {
        // console.log(userData);  
        return this.http.post(this.url + '/GetRoleList', "");
    }

    //get dailyupdate by id
    GetDailyUpdateById(dailyUpdateId: any) {
        return this.http.get(this.url + '/GetDailyUpdateById?dailyUpdateId=' + dailyUpdateId);
    }

    // update sensei daily update
    UpdateDailyUpdate(dailyupdate:DailyUpdate) {
        // console.log(userData);  
        return this.http.post(this.url + '/UpdateDailyUpdate',dailyupdate);
    }

     //delete dailyupdate by id
     DeleteDailyUpdate(dailyUpdateId: any) {
        return this.http.get(this.url + '/DeleteDailyUpdate?dailyUpdateId=' + dailyUpdateId);
    }

    DeleteBeltUpHistory(beltUpId){
        return this.http.get(this.url + '/DeleteBeltUpHistory?beltUpId=' + beltUpId);
        
    }

    GetUserCenter(userId: any) {
        return this.http.get(this.url + '/GetUserCenter?userId=' + userId);
    }

    UpdateUserLogInCenter(centerId: any) {
      return this.http.get(this.url + '/UpdateUserLoginCenter?centerId=' + centerId,);
    }
    
    GelAllCenter(){
        return this.http.get(this.url+'/GetAllCenter');
    }

    // user feedback
    userFeedBack(feedback: FeedBack) {
        const frmData = new FormData();
        frmData.append("Attechment", feedback.Attechment);
        frmData.append("Subject", feedback.Subject);
        frmData.append("Description", feedback.Description);
        frmData.append("Role", feedback.Role);
        frmData.append("UserName", feedback.UserName);
        frmData.append("Severity", feedback.Severity);
        frmData.append("Center", feedback.Center);
        frmData.append("ErrorPage",feedback.ErrorPage);
        
        return this.http.post(this.url + '/SendFeedBack',frmData);
    }
    
    GetModules(){
        return this.http.get(this.url+'/GetModules');
    }



}