import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { DailyUpdate } from '../models/DailyUpdate';
import { Router } from '@angular/router';
import { ExcelService } from '../services/excel.service';
import { DateRangeFilter } from '../models/DateRangeFilter';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.css']
})
export class DailyReportComponent implements OnInit {
  dailyUpdateList: any = [];
  dateRangeFilter = new DateRangeFilter();
  date = new Date();
  firstDay =
    new Date(this.date.getFullYear(), this.date.getMonth(), 1);

  lastDay =
    new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  fromoptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    maxDate: this.lastDay,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY',
    addClass: 'form-control',
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false,
  };
  tooptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    minDate: this.firstDay,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY',
    addClass: 'form-control',
    addStyle: {},
    fieldId: 'searchtoDate',
    useEmptyBarTitle: false,
  };
  readDailyLogPermission: Boolean = false;
  editDailyLogPermission: Boolean = false;
  deleteDailyLogPermission: Boolean = false;
  constructor(public _methods: CommonMethods, public authenticationService: AuthenticationService, private toastr: ToastrService, private _router: Router, private excelService: ExcelService) { }

  ngOnInit() {
    this.dateRangeFilter.StartDate = moment(this.firstDay).format('MM-DD-YYYY');
    this.dateRangeFilter.EndDate = moment(this.lastDay).format('MM-DD-YYYY');
    if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Read)) {
      this.readDailyLogPermission = true;
      this.getDailyUpdate();
    }
    if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Update)) {
      this.editDailyLogPermission = true;
    }
    if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Delete)) {
      this.deleteDailyLogPermission = true;
    }
  }

  getDailyUpdate() {
    let table = $('#dailyUpdateList-table').DataTable({
      "destroy": true
    });

    table.destroy();
    this._methods.showLoader();
    this.authenticationService.GetAllDailyUpdate(this.dateRangeFilter).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.dailyUpdateList = res.Data;
        setTimeout(function () {
          $(function () {
            $('#dailyUpdateList-table').DataTable({
              'order': [[6, 'asc']],
              'destroy':true
            });
          });
        });
      }
      else if (res.StatusCode === 404) {
        this._methods.hideLoader();
        this.dailyUpdateList = [];

      }
      else if (res.StatusCode === 400) {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
        this.dailyUpdateList = [];
      }
      else {
        this._methods.hideLoader();
        this.dailyUpdateList = [];
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {
      this._methods.hideLoader();
      this.toastr.error(error, 'Updating Student Belt Failed!');
    });
  }
  editdailyupdate(id) {
    if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Update)) {
      this._router.navigate([`dailyupdate/${btoa(id)}`]);
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }

  deleteDailyUpdate(item) {
    if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Delete)) {
      if (confirm("Are you sure to delete this dailyupdate?")) {
        this.authenticationService.DeleteDailyUpdate(item.DailyUpdateId).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.toastr.success(res.Data);
            let table = $('#dailyUpdateList-table').DataTable({
              'order': [[6, 'asc']], 'destroy': true
            });
  
            table.destroy();
            this.getDailyUpdate();
          }
          else if (res.StatusCode === 404) {
            this.dailyUpdateList = [];

          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {

          this.toastr.error(error, 'Delete DailyUpdate Failed!');
        });
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }

  //export to excel 
  downloadDailyUpdate() {
    let tempdailylist = this.dailyUpdateList;
    for (let i = 0; i < tempdailylist.length; i++) {
      delete tempdailylist[i]["CreatedBy"];
      // delete tempdailylist[i]["BeltVersionId"];
    }
    this.excelService.exportAsExcelFile(tempdailylist, 'DailyReport');
  }

  //Search to get graduation
  searchGetDailyUpdate() {
    if (this._methods.CheckUserPermission(ModulesEnum.SenseiDailyLog, PermissionEnum.Read)) {
      this.dateRangeFilter.StartDate = moment(this.dateRangeFilter.StartDate).format('MM-DD-YYYY');
      this.dateRangeFilter.EndDate = moment(this.dateRangeFilter.EndDate).format('MM-DD-YYYY');
      this.getDailyUpdate();
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }

  //from date change event
  fromchange(event) {
    this.tooptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      minDate: moment(this.dateRangeFilter.StartDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'searchtoDate',
      useEmptyBarTitle: false,
    }

  }
  tochange(event) {
    this.fromoptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      // minDate: this.dateRangeFilter.StartDate,
      maxDate: moment(this.dateRangeFilter.EndDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {},
      fieldId: 'searchFromDate',
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    };
  }
}
