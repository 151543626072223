import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  SignupForm: FormGroup;
  user = new User();
  submitted = false;
  isMatchCPassword: boolean = true;
  rolelist: any;
  centerList:any=[];
  centerSelectedList:any=[];
  isvalidUsercenter:any=true;
  constructor(private _methods : CommonMethods,private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit() {
    if(this._methods.CheckUserPermission(ModulesEnum.RegisterUser, PermissionEnum.Create))
    {     
    
    this.SignupForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      UserName: ['', Validators.required],
      Password: ['', Validators.required],
      ConfirmPassword: ['', Validators.required],
      RoleId: ['', Validators.required]
    });
    this.getRoleList();
    this.getAllCenter();
  }
  else
  {
    this._methods.logOut();
  }
  }
  // convenience getter for easy access to form fields
  get f() { return this.SignupForm.controls; }

  PasswordMatch(value) {
    if (value.trim() != "") {
      if (this.f.Password.value.trim() == value.trim()) {
        this.isMatchCPassword = true;
      }
      else {
        this.isMatchCPassword = false;
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.SignupForm.invalid) {
      return;
    }
    if(this.centerSelectedList.length>0 && !isNullOrUndefined(this.centerSelectedList))
    {
      this.isvalidUsercenter=true;
      let centerid=this.centerSelectedList.join(",");
      this.user.UserCenter=centerid+",";
    }
    else
    {
      this.isvalidUsercenter=false;
      return;
    }
    this.authenticationService.userSignup(this.user).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        // this.toastr.success(res.message,res.data);
        this.toastr.success("Registration successful.");
        this.SignupForm.reset();
        //this._router.navigate(['login']);
      } else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
        //this.toastr.error(res.data);
      }
      else if (res.statusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {
      // toaster config --message, title, ToastConfig       
      this.toastr.error(error, 'Registration Failed!');
    });
  }

  getRoleList() {
    this.authenticationService.getRoleList().subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {
        this.rolelist = res.Data;
      }
      else {
        this.rolelist = [];
      }
    });
  }

  getAllCenter(){
    this.authenticationService.GelAllCenter().subscribe((res:any)=>{
      if (res.StatusCode === 200) {
        this.centerList = res.Data;
        for (var i = 0; i < this.centerList.length; i++) {
          this.centerList[i].isSelected = false;
        }
      }
      else {
        this.centerList = [];
      }
    })
  }

  centerSelectionChange() {
    this.getCheckedItemList();
   }
 
   getCheckedItemList() {
     this.centerSelectedList = [];
     this.isvalidUsercenter=true;
     for (var i = 0; i < this.centerList.length; i++) {
       if (this.centerList[i].isSelected)
       this.centerSelectedList.push(this.centerList[i].CenterId)
     }
    
   }
}
