import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { timeout, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { UserModulePermissions } from '../models/UserModulePermissions';


@Injectable()
export class CommonMethods {
    public logoImage = '';
    public clientLogoImage = '';
    public clientLogoImageMini = '';
    public loginData: any;
    public isLogin = false;
    public isAdmin = false; // true; //
    private themeWrapper;
    user;
    public isshowLoader = false;
    public UserPermissions: UserModulePermissions[] = [];
    constructor(private _router: Router, private _location: Location) {
        this.loginData = this.getLoginData();
    }

    /* Set Authentication Data */
    setLoginData() {
        this.loginData = this.getLoginData();
        const user = atob(sessionStorage.getItem('authData'));
        this.user = JSON.parse(user);
        if (this.user) {
            this.isLogin = true;
        }
        else {
            this.isLogin = false;
            sessionStorage.clear();
            this._router.navigate(['/login']);
        }
    }

    getLoginData() {
        if (sessionStorage.getItem('authData') === null || sessionStorage.getItem('authData') === undefined) {
            return [];
        } else {
            // this.isLogin = true;
            return JSON.parse(atob(sessionStorage.getItem('authData')));
        }
    }

    isLoggedIn() {
        const localData = atob(sessionStorage.getItem('authData'));
        if (localData !== undefined && localData !== null && localData !== '') {
            const loginDatas = JSON.parse(localData);
            if (loginDatas.UserID > 0) {
                this.isLogin = true;
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    logOut() {

        this.isLogin = false;
        sessionStorage.clear();
        localStorage.clear();
        // tslint:disable-next-line:max-line-length
        this._router.navigate(['/login']);
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    // check user permission globally
    public CheckUserPermission(moduleId: number, permissionId: number) {

        const jsonObject: any = (this.loginData.userPermission);
        this.UserPermissions = jsonObject;
        for (let i = 0; i < jsonObject.length; i++) {
            if (this.UserPermissions[i].ModuleID === moduleId && this.UserPermissions[i].PermissionID === permissionId) {
                return true;
            }
        }
    }

    showLoader() {
        this.isshowLoader = true;
    }
    hideLoader() {
        this.isshowLoader = false;
    }
}
