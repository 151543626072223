export class ProgramReport {

    StudentId: any;
    StudentName: any;
    FirstParentEmail: any;
    SecondParentEmail: any;
    EventName: any;
    EventDate: any;

}

export class ProgramReportSearch {

    StudentId: any;
    StartDate: any;
    EndDate: any;
    EventCode: any;

}